import React from 'react';
import { useIsAuthorised } from './auth-utils';

interface IPrivateComponentProps {
  resource: string;
  operation: string[];
  otherwise?: React.ReactElement<any, any>;
}

const PrivateComponent: React.FunctionComponent<IPrivateComponentProps> = props => {
  const isAuthorised = useIsAuthorised(props.resource, props.operation);
  if (isAuthorised) {
    return <>{props.children}</>;
  }
  if (props.otherwise) {
    return <>{props.otherwise}</>;
  }

  return null;
};

export default PrivateComponent;
