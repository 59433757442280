import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React, { useMemo } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { IGroup } from 'shared/model/group.model';
import { IUser } from 'shared/model/user.model';
import { displayUser } from 'shared/utils/user-utils';
import DataGrid from 'shared/widgets/dataTable';
import DeviceName from 'shared/widgets/devices/deviceName';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1)
    },
    description: {
      fontSize: '1rem',
      fontWeight: 'lighter'
    }
  })
);

export interface IGroupDetailsProps {
  data?: IGroup;
}

const GroupDetails = (props: IGroupDetailsProps) => {
  const group = props.data;
  const classes = useStyles();
  const { t } = useTranslation();
  const users = useSelector(({ users }: IRootState) => users.users);

  const devicesColumns: IDataTableColumn<IDevice>[] = useMemo(
    () => [
      {
        selector: 'device_name',
        name: t('device_name'),
        sortable: true,
        grow: 2,
        format: (row: IDevice) => <DeviceName device={row} />
      }
    ],
    [t]
  );

  const usersColumns: IDataTableColumn<IUser>[] = useMemo(
    () => [
      {
        selector: 'first_name',
        name: t('lastname'),
        sortable: true,
        grow: 2,
        format: (row: IUser) => (
          <Box key={row.id} p={0.5}>
            <Box fontWeight="fontWeightBold">{displayUser(row)}</Box>
            <Box fontWeight="fontWeightLight">{row.email}</Box>
          </Box>
        )
      }
    ],
    [t]
  );

  if (!group) {
    return null;
  }

  const members = users.filter(aUser =>
    aUser.group_memberships.some(aMembership => aMembership.group.group_id === group.group_id)
  );

  return (
    <Box className={classes.root}>
      <Paper elevation={3} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DataGrid
              title={t('device', { count: 100 })}
              noTableHead
              columns={devicesColumns}
              data={group.devices as IDevice[]}
              defaultSortField="notificationTimestamp"
              defaultSortAsc={false}
              paginationPerPage={10}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DataGrid
              title={t('user', { count: 100 })}
              noTableHead
              columns={usersColumns}
              data={members as IUser[]}
              defaultSortField="first_name"
              defaultSortAsc={false}
              paginationPerPage={10}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default GroupDetails;
