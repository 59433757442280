import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { nanolikeCallbackNotificationChoice } from 'shared/model/callback.model';

import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import SelectDevicesOrGroups from 'shared/widgets/form/selectDevicesOrGroups';
import { notificationChoices } from './callbackForm';
import { workspaceIsIbc } from 'shared/utils/workspace-utils';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const POSSIBLEDATATYPES = {
  silo: [
    'lastMessageReceived',
    'level_percent',
    'levelJPlus1Percent',
    'levelJPlus2Percent',
    'levelJPlus3Percent',
    'levelJPlus4Percent',
    'level_kg',
    'level_t',
    'levelJPlus1Kg',
    'levelJPlus2Kg',
    'levelJPlus3Kg',
    'levelJPlus4Kg',
    'levelJPlus1T',
    'levelJPlus2T',
    'levelJPlus3T',
    'levelJPlus4T',
    'missingWeight',
    'missingWeightJPlus1',
    'missingWeightJPlus2',
    'missingWeightJPlus3',
    'missingWeightJPlus4',
    'remainingDays',
    'mean15days',
    'max15days',
    'daily_analysis'
  ],
  ibc: ['level_percent', 'level_liter', 'temperature']
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '1rem',
      marginBotton: '0.2rem'
    },
    type: {
      '&>svg': {
        marginRight: theme.spacing(1)
      }
    },
    toggleButton: {
      height: '100%',
      width: '135px',
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    buttonGroup: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    }
  })
);

export interface ICallbackFormSecondStepProps {
  step: number;
  activeStep: number;
}

const CallbackFormSecondStep = (props: ICallbackFormSecondStepProps) => {
  const { activeStep, step } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const updating = useSelector(({ callbacks }: IRootState) => callbacks.updating);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const form = useFormContext();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';
  const typeOptions = (isIbc ? POSSIBLEDATATYPES.ibc : POSSIBLEDATATYPES.silo).map(dataType => ({
    label: t(`${dataType}`),
    value: dataType
  }));

  const url = form.getValues().url;
  const dataTypesDefaultValue = !isNew ? form.getValues().data_types : [];
  const notification_choice = form.getValues()
    .notification_choice as nanolikeCallbackNotificationChoice;
  const dataTypes = form.watch('data_types') ?? [];

  useEffect(() => {
    form.register(
      { name: 'notification_choice' },
      {
        validate: value => {
          if (activeStep === step && !form.getValues().notification_choice) {
            return false;
          }
          return true;
        }
      }
    );
  }, [activeStep, form, step]);

  const onNotificationTypeChange = (e: any, selection: any) => {
    form.setValue('notification_choice', selection);
    form.triggerValidation();
  };
  const onSelectAllDataTypeChange = () => {
    const newSelection = allDataTypesSelected ? [] : typeOptions;
    form.setValue('data_types', newSelection, true);
  };
  const allDataTypesSelected = typeOptions.every(option =>
    dataTypes.some((dt: { value: string }) => dt.value === option.value)
  );
  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      <Box textAlign="center">
        <Typography>{url}</Typography>
      </Box>
      <Grid container justify="center">
        <Grid item xs={12} md={6} lg={4}>
          <Box pt={3}>
            <Box textAlign="center" className={classes.label}>
              {t('notification_choice')}
            </Box>
            <Box display="flex" justifyContent="center" pb={1}>
              <ToggleButtonGroup
                value={notification_choice}
                exclusive
                onChange={onNotificationTypeChange}
                className={classes.buttonGroup}
              >
                {notificationChoices.map(notifType => {
                  return (
                    <ToggleButton
                      value={notifType}
                      key={notifType}
                      className={classes.toggleButton}
                      disabled={notifType === 'SLACK'}
                    >
                      <Box>{t(notifType)}</Box>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Box>
            <Box>
              {form.errors && form.errors.notification_choice && (
                <Alert severity="error">{t('required_field')}</Alert>
              )}
            </Box>
            <Box textAlign="center">{t('notification_choice_explanation')}</Box>
          </Box>
          <SelectDevicesOrGroups
            step={step}
            activeStep={activeStep}
            name="device_group"
            label={t('entities')}
            disabled={updating}
            selectAll
          />
          <MultipleAutoComplete
            name="data_types"
            label={t('select_data')}
            defaultValue={dataTypesDefaultValue}
            options={typeOptions}
            validate={(value: any) => {
              if (activeStep === step && (!value || value.length === 0)) {
                return <Trans i18nKey="required_field">Required Field</Trans>;
              }
              return true;
            }}
            disabled={updating}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={allDataTypesSelected}
                onChange={onSelectAllDataTypeChange}
                size="small"
              />
            }
            label={t('select_all_datatypes')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallbackFormSecondStep;
