import { TFunction } from 'i18next';
import { getGroupsDevices } from 'shared/auth/auth-utils';
import { IDevice } from 'shared/model/device.model';
import { IGroup } from 'shared/model/group.model';
import { sortOptionsByLabel } from 'shared/utils/data-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';

export type ibcType = 'ibc';
export type tankType = 'tank';
export type siloType = 'silo';

export type deviceType = ibcType | tankType | siloType;

export const getDeviceType = (device: IDevice): deviceType => {
  return device.is_silo ? 'silo' : device.is_tank ? 'tank' : 'ibc';
};

export const getDevicesTypes = (devices: IDevice[]): deviceType[] =>
  devices.reduce((agg: deviceType[], current) => {
    const deviceType = getDeviceType(current);
    if (!agg.some(item => item === deviceType)) {
      agg.push(deviceType);
    }
    return agg;
  }, []);

export const getDeviceTypeOptions = (group: IGroup[], t: TFunction) => {
  const devices = getGroupsDevices(group);
  const devicesTypes = getDevicesTypes(devices);

  const options = devicesTypes.map(deviceType => ({
    label: t(`${deviceType}`),
    value: deviceType
  }));
  options.sort(sortOptionsByLabel);

  return options;
};

export const getSeletedDeviceTypeOptions = (
  deviceOrGroupOptions: ILabelValueOption[],
  devices: IDevice[],
  groups: IGroup[],
  t: TFunction
) => {
  const groupLabel = t('group');
  return deviceOrGroupOptions.reduce((agg: ILabelValueOption[], current) => {
    const groupOrDeviceId = current.value;
    const isGroup = current.group === groupLabel;

    let selectedDevices: IDevice[] = [];
    if (isGroup) {
      const group = groups.find(item => item.group_id === groupOrDeviceId);
      if (group && group.devices) {
        selectedDevices = selectedDevices.concat(group.devices);
      }
    } else {
      const device = devices.find(item => item.device_id === groupOrDeviceId);
      if (device) {
        selectedDevices.push(device);
      }
    }
    const devicesTypes = getDevicesTypes(selectedDevices);

    devicesTypes.forEach(deviceType => {
      if (!agg.some(item => item.value === deviceType)) {
        agg.push({
          label: t(`${deviceType}`),
          value: deviceType
        });
      }
    });
    return agg;
  }, []);
};
