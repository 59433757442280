import { IRootState } from 'config/store';
import Alerts from 'modules/alerts/alerts';
import CreateOrEditAlertForm from 'modules/alerts/form/createOrEditAlertForm';
import Callbacks from 'modules/callbacks/callbacks';
import CreateOrEditCallback from 'modules/callbacks/form/createOrEditCallback';
import Dashboard from 'modules/dashboard/dashboard';
import CreateOrEditGraphForm from 'modules/dashboard/form/createOrEditGraphForm';
import Dashboards from 'modules/dashboards/dashboards';
import Device from 'modules/devices/device';
import Devices from 'modules/devices/devices';
import CreateOrEditGroup from 'modules/groupsDevices/form/createOrEditGroup';
import GroupsDevices from 'modules/groupsDevices/groups';
import Home from 'modules/home/home';
import KpiForm from 'modules/kpi/kpiForm';
import Products from 'modules/products/products';
import UserPassword from 'modules/profile/userPassword';
import UserProfile from 'modules/profile/userProfile';
import CreateOrEditRule from 'modules/rules/form/createOrEditRule';
import Rules from 'modules/rules/rules';
import { siloOrdersTo } from 'modules/siloOrders';
import Users from 'modules/users/users';
import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/auth/private-route';
import ErrorBoundaryRoute from 'shared/error/errorBoundaryRoute';
import installerScreen from 'shared/error/installerScreen';
import { workspaceIsIbc, workspaceIsIndus, workspaceIsSilo } from 'shared/utils/workspace-utils';
import Loading from 'shared/widgets/loading';

const SiloOrders = Loadable({
  loader: () => import(/* webpackChunkName: "siloOrders" */ 'modules/siloOrders'),
  loading: () => <Loading />
});

const Routes = () => {
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const isIndus = workspaceIsIndus(settings);
  const isIbc = workspaceIsIbc(settings);
  const me = useSelector(({ authentication }: IRootState) => authentication.me);
  const isInstaller = me?.workspace_role?.name?.trim().toLowerCase() === 'installer';

  if (isInstaller) {
    return <Route component={installerScreen} />;
  }
  return (
    <Switch>
      <PrivateRoute
        path="/dashboard/:dashboardId/graph/:id"
        component={CreateOrEditGraphForm}
        resource="Graph"
        operation={['UPDATE']}
      />
      <ErrorBoundaryRoute path="/dashboard/:id" component={Dashboard} />
      <PrivateRoute
        path="/dashboards"
        component={Dashboards}
        resource="menu_dashboard"
        operation={['READ']}
      />
      <PrivateRoute path="/users" component={Users} resource="menu_users" operation={['READ']} />
      <PrivateRoute
        path="/rule/:id"
        component={CreateOrEditRule}
        resource="Rule"
        operation={['UPDATE']}
      />
      <PrivateRoute path="/rules" component={Rules} resource="menu_rules" operation={['READ']} />
      <PrivateRoute
        path="/alerts/:id"
        component={CreateOrEditAlertForm}
        resource="Alert"
        operation={['UPDATE']}
      />
      <PrivateRoute path="/alerts" component={Alerts} resource="menu_alerts" operation={['READ']} />
      <PrivateRoute path="/devices/:id" component={Device} resource="Device" operation={['READ']} />
      <PrivateRoute path="/devices" component={Devices} resource="Device" operation={['READ']} />
      <PrivateRoute
        path="/callbacks"
        component={Callbacks}
        resource="menu_callbacks"
        operation={['READ']}
      />
      <PrivateRoute
        path="/callback/:id"
        component={CreateOrEditCallback}
        resource="Callback"
        operation={['UPDATE']}
      />
      <PrivateRoute
        path="/groups/"
        component={GroupsDevices}
        resource="menu_groups"
        operation={['READ']}
      />
      <PrivateRoute
        path="/group/:id"
        component={CreateOrEditGroup}
        resource="Group"
        operation={['UPDATE']}
      />
      {isIndus && (
        <PrivateRoute
          path="/products"
          component={Products}
          resource="menu_products"
          operation={['READ']}
        />
      )}
      {isIbc && (
        <PrivateRoute path="/kpi" component={KpiForm} resource="menu_kpi" operation={['READ']} />
      )}
      <ErrorBoundaryRoute path="/user-profile" exact component={UserProfile} />
      <ErrorBoundaryRoute path="/user-password" exact component={UserPassword} />
      {isSilo && (
        <PrivateRoute
          path={siloOrdersTo}
          component={SiloOrders}
          resource="Order"
          operation={['READ']}
        />
      )}
      <Route path="/" exact render={Home} />
      <Route component={Home} />
    </Switch>
  );
};

export default Routes;
