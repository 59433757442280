export const GOOGLE_MAP_KEY = 'REACT_APP_GOOGLE_MAP_KEY';

export interface IApplicationEnv {
  workspace: string;
  apiUrl: string;
  nodeJsEnv: string;
  googleMapKey: string;
  rights?: string[];
  searchRight?: Function;
}

declare global {
  interface Window {
    env: IApplicationEnv;
  }
}

export const isProd = () => {
  return process.env.NODE_ENV === 'production';
};

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

const loadFromUrl = (): IApplicationEnv => {
  const url = /(https?:\/\/)(.*?)\.(.*?)\/(.*)/.exec(window.location.href);
  if (url) {
    const protocol = url[1];
    const host = url[3];
    let workspace = url[2];
    let apiUrl = `${protocol}api.${host}/v1`;
    return {
      workspace,
      apiUrl,
      googleMapKey: process.env[GOOGLE_MAP_KEY] as string,
      nodeJsEnv: process.env.NODE_ENV
    };
  }
  throw new Error('Cannot load env from url !');
};

const loadFromProcessEnv = (): IApplicationEnv => {
  if (!process.env.REACT_APP_WORKSPACE || !process.env.REACT_APP_API_ENDPOINT) {
    throw new Error(
      'Workspace and API endpoint must be set in .env.local file.\nSee .env.local.sample for more information.'
    );
  }

  return {
    workspace: process.env.REACT_APP_WORKSPACE as string,
    apiUrl: process.env.REACT_APP_API_ENDPOINT as string,
    googleMapKey: process.env[GOOGLE_MAP_KEY] as string,
    nodeJsEnv: process.env.NODE_ENV
  };
};

const loadEnv = () => (isProd() ? loadFromUrl() : loadFromProcessEnv());

const env = loadEnv();
//usefull for debug and maintenance
window.env = env;

export const getEnvApiUrl = (): string => env.apiUrl;
export const getEnvWorkspace = (): string => env.workspace;
export const getEnvGoogleMapKey = (): string => env.googleMapKey;
export const getBinconnectUrl = (): string => {
  const path = window.location.pathname;
  const search = window.location.search;
  const host = window.location.host.replace('nanolike', 'binconnect');
  return `//${host}${path}${search}`;
};
