import { MuiThemeProvider } from '@material-ui/core';
import useConfigTheme from 'config/theme';
import React, { useEffect } from 'react';
import { render } from 'react-dom';

export const useMapControl = (
  map: google.maps.Map | null,
  controlPosition: google.maps.ControlPosition | null,
  children: React.ReactElement
) => {
  const theme = useConfigTheme();

  useEffect(() => {
    if (map && controlPosition) {
      render(
        <MuiThemeProvider theme={theme.muiTheme}>
          <div ref={el => map.controls[controlPosition].push(el as HTMLElement)}>{children}</div>
        </MuiThemeProvider>,
        document.createElement('div')
      );
    }
    return () => {
      if (map && controlPosition) {
        map.controls[controlPosition].clear();
      }
    };
  }, [map, controlPosition, children, theme]);
};
