import { IRootState } from 'config/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import CombinedDeviceDetails from './combinedDeviceDetails';
import DefaultDeviceDetails from './defaultdeviceDetails';

export interface IDeviceDetailsProps {
  data?: IDevice;
}

const DeviceDetails = (props: IDeviceDetailsProps) => {
  const { data } = props;
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  if (!data) {
    return null;
  }

  if (isSilo && data.is_combined) {
    return <CombinedDeviceDetails data={data} />;
  }

  return <DefaultDeviceDetails data={data} />;
};

export default DeviceDetails;
