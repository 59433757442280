import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { nanolikeDataType } from 'shared/model/api.model';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsIbc, workspaceIsIndus } from 'shared/utils/workspace-utils';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import SelectLocation from './selectLocation';
import SelectMax from './selectMax';
import SelectMin from './selectMin';
import SelectMinMax from './selectMinMax';
import SelectTimeThrehold from './selectTimeThreshold';
import { useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';

const ibcDataTypeAlertSubSet: nanolikeDataType[] = [
  'level_percent',
  'remainingDays',
  'lastMessageReceived',
  'level_liter',
  'missingLiter',
  'temperature',
  'position'
];

const siloDataTypeAlertSubset: nanolikeDataType[] = ['level_percent', 'remainingDays', 'level_t'];
const indusDataTypeAlertSubset: nanolikeDataType[] = ['level_percent', 'missingWeight', 'level_t'];

const getAvailableTypes = (isIbc: boolean, isIndus: boolean) =>
  isIbc ? ibcDataTypeAlertSubSet : isIndus ? indusDataTypeAlertSubset : siloDataTypeAlertSubset;

export interface IAlertFormSecondStepProps {
  step: number;
  activeStep: number;
}

const AlertFormSecondStep = (props: IAlertFormSecondStepProps) => {
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);
  const isIndus = workspaceIsIndus(settings);
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const { watch, getValues } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;

  const selectedDataType = watch<string, ILabelValueOption>('data_type');
  const dataTypes = getAvailableTypes(isIbc, isIndus).map((item: nanolikeDataType) => ({
    label: localizedDataTypeWithUnit(item),
    value: item
  }));

  const defaultValue = !isNew ? getValues().data_type : undefined;

  return (
    <Box display={activeStep === step ? 'block' : 'none'} marginBottom={4}>
      <SingleAutoComplete
        name="data_type"
        label={t('data_type')}
        defaultValue={defaultValue}
        options={dataTypes}
        validate={(value: any) => {
          if (activeStep === step && !value) {
            return <Trans i18nKey="required_field">Required Field</Trans>;
          }
          return true;
        }}
      />
      {selectedDataType && (
        <Box>
          {selectedDataType.value === 'position' ? (
            <SelectLocation step={step} activeStep={activeStep} />
          ) : selectedDataType.value === 'lastMessageReceived' ? (
            <SelectTimeThrehold step={step} activeStep={activeStep} />
          ) : selectedDataType.value === 'remainingDays' ? (
            <SelectMin step={step} activeStep={activeStep} />
          ) : selectedDataType.value === 'missingWeight' ? (
            <SelectMax step={step} activeStep={activeStep} />
          ) : (
            <SelectMinMax step={step} activeStep={activeStep} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AlertFormSecondStep;
