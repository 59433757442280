import { graphType } from 'shared/model/graph.model';
import {
  deviceContentDef,
  mapFilters,
  tableFilters,
  temperatureDef,
  missingWeightDef
} from './filterGraph.model';

export const getFilters = (graphType: graphType, workspaceType: string) => {
  const all =
    graphType === 'table' ? [...tableFilters] : graphType === 'map' ? [...mapFilters] : [];
  if (workspaceType === 'ibc') {
    if (graphType === 'table') {
      all.push(temperatureDef);
    }
  }
  if (workspaceType.includes('silo')) {
    if (graphType === 'table') {
      all.push(deviceContentDef);
    }
  }
  if (workspaceType === 'silo_industry') {
    if (graphType === 'table') {
      all.push(missingWeightDef);
    }
  }
  return all;
};

export const getDefaultValues = (graphType: graphType, workspaceType: string) => {
  const filters = getFilters(graphType, workspaceType);
  return filters.map(item => item.defaultValue);
};
