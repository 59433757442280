import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IRootState } from 'config/store';
import LoadingWorkspace from 'modules/login/loading-workspace';
import Login from 'modules/login/login';
import Register from 'modules/register/register';
import ResetPassword from 'modules/resetPassword/resetPassword';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Layout, { HEADER_HEIGHT } from 'shared/layout/layout';
import { loginByToken } from 'shared/reducers/authenticationSlice';
import Storage, { JWT_TOKEN_REFRESH_KEY } from 'shared/utils/storage-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      background: theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[50]
    },
    authenticated: {
      marginTop: `${HEADER_HEIGHT}px`,
      height: `calc(100vh - ${HEADER_HEIGHT}px)`
    }
  })
);

const Landing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authentication = useSelector(({ authentication }: IRootState) => authentication);
  const wsSuccess = useSelector(({ workspace }: IRootState) => workspace.wsSuccess);
  const location = useLocation();
  const { isAuthenticated, loginSuccess } = authentication;

  useEffect(() => {
    const existingToken = Storage.session.get(JWT_TOKEN_REFRESH_KEY);
    if (existingToken) {
      dispatch(loginByToken(existingToken));
    }
  }, [dispatch]);

  if (location.pathname === '/register') {
    return <Register />;
  } else if (location.pathname === '/reset-password') {
    return <ResetPassword />;
  }
  // else if (location.pathname === '/coverage') { return <CoveragePage/>}

  const existingToken = Storage.session.get(JWT_TOKEN_REFRESH_KEY);

  return (
    <div
      className={clsx(classes.root, {
        [classes.authenticated]: wsSuccess && isAuthenticated
      })}
    >
      {wsSuccess && isAuthenticated ? (
        <Layout />
      ) : loginSuccess ? (
        <LoadingWorkspace />
      ) : !existingToken ? (
        <Login />
      ) : null}
    </div>
  );
};

export default Landing;
