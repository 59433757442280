/* eslint-disable no-throw-literal */
export default function parseDms(dmsString) {
  dmsString = dmsString.trim();
  // See https://www.regextester.com/93451
  // var dmsRe = /([NSEW])?\s?(-)?(\d+(?:\.\d+)?)[°º:d\s]?\s?(?:(\d+(?:\.\d+)?)['’‘′:]?\s?(?:(\d{1,2}(?:\.\d+)?)(?:"|″|’’|'')?)?)?\s?([NSEW])?/i;
  var dmsRe = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g;

  const m1 = dmsString.match(dmsRe);
  if (!m1) throw 'COULD_NOT_PARSE';
  const latLng = m1[0].split(',');
  return { lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1]) };
}
