import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, { useMemo } from 'react';
import ReactDataTable, { IDataTableProps } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  })
);

const sortIcon = <ArrowDownwardIcon />;

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.progress}>
      <LinearProgress />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    }
  },
  rows: {
    style: {
      flexShrink: 0 // prevent row to be reduce some times weird I need it
    }
  }
};

const selectProps = { indeterminate: (isIndeterminate: any) => isIndeterminate, size: 'small' };

const DataGrid = (props: IDataTableProps<any>) => {
  const { data, title, columns, paginationPerPage = 15, ...rest } = props;
  const { t } = useTranslation();

  const contextMessage = useMemo(
    () => ({
      singular: t('item_selected'),
      plural: t('items_selected'),
      message: t('table_selected')
    }),
    [t]
  );

  const paginationComponentOptions = useMemo(
    () => ({
      rowsPerPageText: t('rowPerPage'),
      rangeSeparatorText: t('rangeSeparatorText'),
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: t('selectAllRowsItemText')
    }),
    [t]
  );

  return (
    <ReactDataTable
      title={title}
      columns={columns}
      data={data}
      sortIcon={sortIcon}
      customStyles={customStyles}
      contextMessage={contextMessage}
      highlightOnHover
      // pagination
      fixedHeader
      dense
      responsive
      persistTableHead
      pointerOnHover
      noDataComponent={<Box p={3}>{t('no_data_available')}</Box>}
      paginationPerPage={paginationPerPage}
      paginationComponentOptions={paginationComponentOptions}
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      progressComponent={<LinearIndeterminate />}
      theme="nanolike"
      {...rest}
    />
  );
};

export default DataGrid;
