import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import products from './products';
export const siloProductsTo = '/products';

const Routes = () => (
  <div>
    <PrivateRoute
      path={siloProductsTo}
      exact
      component={products}
      resource="DeviceContent"
      operation={['READ']}
    />
  </div>
);

export default Routes;
