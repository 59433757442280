import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sortOptionsByLabel } from 'shared/utils/data-utils';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import MultipleAutoComplete from './multipleAutocomplete';

export interface ISelectDevicesOrGroupsProps {
  step: number;
  activeStep: number;
  name: string;
  label: string;
  disabled: boolean;
  onSelectionChange?: Function;
  selectAll?: boolean;
}

const SelectDevicesOrGroups = (props: ISelectDevicesOrGroupsProps) => {
  const { activeStep, step, name, label, disabled, onSelectionChange, selectAll = false } = props;
  const { t } = useTranslation();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  const form = useFormContext();

  const groupGroup = t('group');
  const deviceGroup = t('device', { count: 100 });

  const [devicesSelection, setDevicesSelection] = useState<ILabelValueOption[]>([]);

  const values = (form.getValues(name) ? form.getValues(name) : []) as ILabelValueOption[];
  const deviceValues = values.filter(aValue => aValue.group === deviceGroup);
  useEffect(() => {
    if (deviceValues.length !== devicesSelection.length) {
      setDevicesSelection(deviceValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceValues.length]);

  const groupOptions = groups.map(aGroup => ({
    label: aGroup.group_name,
    value: aGroup.group_id,
    group: groupGroup
  }));
  groupOptions.sort(sortOptionsByLabel);

  const devicesOptions = devices.map(aDevice => ({
    label: getDeviceLabel(aDevice, isSilo),
    value: aDevice.device_id,
    group: deviceGroup
  }));
  devicesOptions.sort(sortOptionsByLabel);

  const deviceGroupOptions = groupOptions.concat(devicesOptions);

  const allDeviceSelected = devicesOptions.every(deviceOption => {
    return devicesSelection.some(deviceSelection => deviceSelection.value === deviceOption.value);
  });

  const onSelectAllChange = () => {
    const newDeviceSelection = (allDeviceSelected ? [] : devicesOptions) as ILabelValueOption[];
    setDevicesSelection(newDeviceSelection);

    const values = (form.getValues(name) ? form.getValues(name) : []) as ILabelValueOption[];
    const groupValues = values.filter(aValue => aValue.group === groupGroup);

    const newSelection = newDeviceSelection.concat(groupValues);

    form.setValue(name, newSelection, true);
    if (onSelectionChange) {
      onSelectionChange(newSelection);
    }
  };

  const defaultOptions = deviceGroupOptions.filter(deviceGroup => {
    const values = form.getValues(name) as ILabelValueOption[];
    return values ? values.some(aValue => aValue.value === deviceGroup.value) : false;
  });

  return (
    <Box>
      <MultipleAutoComplete
        name={name}
        label={label}
        defaultValue={defaultOptions}
        options={deviceGroupOptions}
        onSelectionChange={onSelectionChange}
        validate={(value: any) => {
          if (activeStep === step && !value) {
            return <Trans i18nKey="required_field">Required Field</Trans>;
          }
          return true;
        }}
        disabled={disabled}
      />
      {selectAll && (
        <Box display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Checkbox checked={allDeviceSelected} onChange={onSelectAllChange} size="small" />
            }
            label={t('select_all_devices')}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
};

export default SelectDevicesOrGroups;
