import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useConfigTheme from '../../config/theme';
import Typography from '@material-ui/core/Typography';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const LeftLanding = () => {
  const configTheme = useConfigTheme();
  return (
    <Hidden xsDown>
      <Grid
        item
        sm={6}
        style={{
          backgroundColor: configTheme.backgroundLoginColor,
          color: '#FFF',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }} p={4}>
          <img src={configTheme.logo} alt="NanoLike" width="100%" />
          <Box style={{ textAlign: 'left' }} mt={16}>
            <Typography variant="h3">{configTheme.welcomeText}</Typography>
            <Typography variant="h6">{configTheme.welcomeDescription}</Typography>
          </Box>
        </Box>
      </Grid>
    </Hidden>
  );
};

LeftLanding.propTypes = propTypes;
LeftLanding.defaultProps = defaultProps;
// #endregion

export default LeftLanding;
