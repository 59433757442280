import { useMediaQuery } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useIsAuthorised } from 'shared/auth/auth-utils';
import { closeLeftMenu } from 'shared/reducers/layoutSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'unset'
    },
    selected: {
      color: theme.palette.primary.main
    },
    menuIcon: {
      minWidth: theme.spacing(5),
      '&.selected>svg': {
        color: theme.palette.primary.main
      }
    },
    menuText: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  })
);

export interface MenuItemProps {
  icon: React.ReactElement;
  primary: string;
  resource?: string;
  operation?: string[];
  to?: string;
  onClick?: any;
  selected?: boolean;
}

const MenuItem = (props: MenuItemProps) => {
  const { primary, to, icon, onClick } = props;
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const isAuthorised = useIsAuthorised(props.resource, props.operation);

  if (!isAuthorised) {
    return null;
  }

  const selected = props.selected ? props.selected : location.pathname === to;
  const text = t(primary, { count: 100 });

  const onListItemClick = () => {
    if (onClick) {
      onClick();
    }
    if (isSmall) {
      dispatch(closeLeftMenu());
    }
  };

  return (
    <ListItem
      button
      selected={selected}
      title={text}
      component={to ? Link : 'li'}
      to={to}
      onClick={onListItemClick}
      className={clsx({
        [classes.selected]: selected
      })}
    >
      <ListItemIcon
        className={clsx(classes.menuIcon, {
          [classes.selected]: selected
        })}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} className={classes.menuText} />
    </ListItem>
  );
};

export default MenuItem;
