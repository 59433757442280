import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import SiloOrderForm from './SiloOrderForm';
import SiloOrders from './siloOrders';
export const siloOrdersTo = '/silo-orders';
export const newSiloOrdersTo = '/silo-orders/new';

const Routes = () => (
  <div>
    <PrivateRoute
      path={newSiloOrdersTo}
      exact
      component={SiloOrderForm}
      resource="Calibrations"
      operation={['UPDATE']}
    />
    <PrivateRoute
      path={siloOrdersTo}
      exact
      component={SiloOrders}
      resource="menu_deliveries"
      operation={['READ']}
    />
  </div>
);

export default Routes;
