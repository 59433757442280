import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPng from 'shared/icons/error.png';
import greenAtlasMarker from 'shared/icons/green_atlas_marker.png';
import greenWifiMarker from 'shared/icons/green_wifi_marker.png';
import PendingPng from 'shared/icons/pending.png';
import { levelThresholds } from 'shared/widgets/visualization/graphLevel';
import defaultWifiMarker from 'shared/icons/default_wifi_marker.png';

const items = [
  {
    title: `< ${levelThresholds[0]} %`,
    style: { backgroundColor: '#c60000' }
  },
  {
    title: '',
    style: { backgroundColor: '#f39c12' }
  },
  {
    title: `> ${levelThresholds[1]} %`,
    style: { backgroundColor: '#4caf50' }
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.common.white
    },
    btn: {
      maxWidth: '40px',
      maxHeight: '40px',
      minWidth: '40px',
      minHeight: '40px',
      marginLeft: '10px',
      marginRight: '10px',
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.white
      }
    },
    card: {
      '&>*': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1) + 'px !important'
      }
    }
  })
);

// @ts-ignore
const MapDeviceLevelLegend = ({ isIbc }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" alignItems="top">
      {open && (
        <Card className={classes.card}>
          <CardContent>
            <Box textAlign="center" marginBottom={0.5} fontSize="1rem" fontWeight="bold">
              {t('legendTitle')}
            </Box>
            <Box textAlign="center" marginBottom={1} fontSize="0.8rem">
              {t('mapLegend')}
            </Box>
            <Box
              height="20px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              className={classes.background}
            >
              {items.map(item => (
                <Box
                  key={item.title}
                  height="20px"
                  width="80px"
                  style={item.style}
                  title={item.title}
                  fontSize="0.8rem"
                  paddingTop="2px"
                  textAlign="center"
                  color="white"
                  fontWeight="bold"
                >
                  {item.title}
                </Box>
              ))}
            </Box>
            {!!isIbc && (
              <>
                <Box
                  textAlign="center"
                  marginBottom={0.5}
                  fontSize="1rem"
                  fontWeight="bold"
                  marginTop={1.5}
                >
                  {t('legendGeoloc')}
                </Box>
                <Box display="flex" justifyContent="space-around">
                  <Box title={t('defaultDeviceIcon')}>
                    <Box textAlign="center">
                      <img src={greenAtlasMarker} alt={t('defaultDeviceIcon')} height="40px" />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center">
                      {t('defaultDeviceIcon')}
                    </Box>
                  </Box>
                  <Box title="Wifi">
                    <Box textAlign="center">
                      <img src={greenWifiMarker} alt="Wifi" height="40px" />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center">
                      Wifi
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <Box
              textAlign="center"
              marginBottom={0}
              fontSize="1rem"
              fontWeight="bold"
              marginTop={1.5}
            >
              {t('devices_status.status')}
            </Box>
            <Box display="flex" justifyContent="space-around">
              {!isIbc ? (
                <>
                  <Box title={t('devices_status.pending.label')}>
                    <Box textAlign="center">
                      <img src={PendingPng} alt={t('devices_status.pending.label')} height="40px" />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center" paddingRight={1}>
                      {t('devices_status.pending.label')}
                    </Box>
                  </Box>
                  <Box title={t('devices_status.error.label')}>
                    <Box textAlign="center">
                      <img src={ErrorPng} alt={t('devices_status.error.label')} height="40px" />
                    </Box>
                    <Box fontSize="0.8rem" textAlign="center">
                      {t('devices_status.error.label')}
                    </Box>
                  </Box>
                </>
              ) : (
                <Box>
                  <Box textAlign="center">
                    <img
                      src={defaultWifiMarker}
                      alt={t('devices_status.level_problem.label')}
                      height="40px"
                    />
                  </Box>
                  <Box fontSize="0.8rem" textAlign="center">
                    {t('devices_status.level_problem.label')}
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
      <Button variant="outlined" size="small" onClick={toggle} className={classes.btn}>
        <InfoIcon style={{ color: 'grey' }} />
      </Button>
    </Box>
  );
};
export default MapDeviceLevelLegend;
