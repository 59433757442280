import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { errorNotification, successNotification } from 'shared/reducers/notifierSlice';
import { validPasswordStrength } from 'shared/utils/user-utils';
import PasswordTextField from 'shared/widgets/form/passwordTextField';

const apiUrl = getEnvApiUrl();

interface IFormResponse {
  newPassword: string;
  confirmPassword: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      width: '20rem'
    },
    avatar: {
      backgroundColor: theme.palette.error.main,
      margin: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    resetPasswordBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    resetPasswordTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  })
);

const ResetPasswordCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [updating, setUpdating] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm<IFormResponse>({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      confirmPassword: ''
    }
  });

  const tryResetPwd = async (responses: IFormResponse) => {
    const body: any = {
      new_password: responses.newPassword
    };

    try {
      setUpdating(true);
      await axios.post(`${apiUrl}/reset-user-password?token=${token}`, body);
      setUpdating(false);
      dispatch(successNotification(t('reset_password_success')));
      history.push('/');
    } catch (e) {
      setUpdating(false);
      dispatch(errorNotification(t('reset_password_error')));
    }
  };

  const onSubmit = form.handleSubmit((responses: IFormResponse) => {
    tryResetPwd(responses);
  });

  return (
    <FormContext {...form}>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}>
        <Card>
          <CardContent>
            <Avatar className={classes.avatar}>
              <AccountCircleIcon />
            </Avatar>
            <Box>
              <Typography>{t('reset_password_title')}</Typography>
            </Box>
            <PasswordTextField
              margin="dense"
              required
              fullWidth
              label={t('new_password')}
              name="newPassword"
              autoComplete="new-password"
              disabled={updating}
              inputRef={form.register({
                validate: value => {
                  if (!value) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  if (!validPasswordStrength(value)) {
                    return <Trans i18nKey="password_check">password_check</Trans>;
                  }
                  return true;
                }
              })}
              error={form.errors.newPassword ? true : false}
              helperText={form.errors.newPassword && form.errors.newPassword.message}
            />
            <PasswordTextField
              margin="dense"
              required
              fullWidth
              label={t('confirm_new_password')}
              name="confirmPassword"
              autoComplete="new-password"
              disabled={updating}
              inputRef={form.register({
                validate: value => {
                  if (!value) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  const newPwd = form.getValues().newPassword;
                  if (newPwd !== value) {
                    return <Trans i18nKey="different_passwords">different_passwords</Trans>;
                  }

                  if (!validPasswordStrength(value)) {
                    return <Trans i18nKey="password_check">password_check</Trans>;
                  }
                  return true;
                }
              })}
              error={form.errors.confirmPassword ? true : false}
              helperText={form.errors.confirmPassword && form.errors.confirmPassword.message}
            />
          </CardContent>
          <CardActions>
            <Button color="primary" variant="contained" type="submit" fullWidth disabled={updating}>
              {t('reset_password')}
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormContext>
  );
};

export default ResetPasswordCard;
