import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceGroup from 'modules/devices/deviceGroup';
import { Box } from '@material-ui/core';
import GroupsDialog from 'modules/devices/GroupsDialog';
import { ArrowForward } from '@material-ui/icons';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  poi: PropTypes.shape({}),
  groups: PropTypes.arrayOf(PropTypes.shape({}))
};

const defaultProps = { poi: false, groups: [] };

/**
 *
 */
function GroupCell({ groups }) {
  const [openGroupsModal, setOpenGroupsModal] = useState(false);
  const handleClick = () => setOpenGroupsModal(true);
  const { t } = useTranslation();
  const poi = groups.find(g => g.is_poi);
  return (
    <Box
      display="flex"
      direction="row"
      spacing={1}
      alignItems={'center'}
      justifyContent={'flexEnd'}
    >
      {!!groups.length && <DeviceGroup group={poi || groups[0]} />}
      {groups.length > 1 && (
        <Chip
          label={t('moreWithCount', { count: groups.length - 1 })}
          onClick={handleClick}
          variant="outlined"
          size="small"
          style={{
            margin: 0.5,
            borderRadius: '10px'
          }}
          deleteIcon={<ArrowForward />}
          onDelete={handleClick}
        />
      )}
      <GroupsDialog
        open={openGroupsModal}
        handleClose={() => setOpenGroupsModal(false)}
        groups={groups}
      />
    </Box>
  );
}

GroupCell.propTypes = propTypes;
GroupCell.defaultProps = defaultProps;
// #endregion

export default GroupCell;
