import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IDevice } from 'shared/model/device.model';
import { fetchDevice } from 'shared/reducers/devicesSlice';
import Loading from 'shared/widgets/loading';
import DeviceDetails from './deviceDetails';
import DeviceToolbar from './deviceToolbar';

const Device = () => {
  const dispatch = useDispatch();
  const device = useSelector(({ devices }: IRootState) => devices.device);
  const loading = useSelector(({ devices }: IRootState) => devices.loading);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(fetchDevice(id));
    }
  }, [dispatch, id]);

  return (
    <Box p={1}>
      {loading ? (
        <Loading />
      ) : device ? (
        <>
          <DeviceToolbar />
          <DeviceDetails data={device as IDevice} />
        </>
      ) : null}
    </Box>
  );
};

export default Device;
