import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IGraph } from 'shared/model/graph.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  graphs: [] as IGraph[],
  graph: null as IGraph | null,
  errorMessage: ''
};

export type GraphsState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'graphs',
  initialState,
  reducers: {
    fetchGraphsStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchGraphsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchGraphsSuccess: (state, action: PayloadAction<IGraph[]>) => {
      state.loading = false;
      state.graphs = action.payload;
    },
    fetchGraphSuccess: (state, action: PayloadAction<IGraph>) => {
      state.loading = false;
      state.graph = action.payload;
    },
    updateGraphStart: state => {
      state.errorMessage = '';
      state.updateSuccess = false;
      state.updating = true;
    },
    updateGraphFailed: (state, action: PayloadAction<string>) => {
      state.updateSuccess = false;
      state.updating = false;
      state.errorMessage = action.payload;
    },
    updateGraphSuccess: (state, action: PayloadAction<IGraph>) => {
      state.updateSuccess = true;
      state.updating = false;
      state.graph = action.payload;
    },
    updateFilterSuccess: (state, action: PayloadAction<IGraph>) => {
      const newGraphs = [...state.graphs];
      const index = newGraphs.findIndex(item => item.graph_id === action.payload.graph_id);
      if (index !== -1) {
        newGraphs.splice(index, 1);
      }
      newGraphs.push(action.payload);
      state.graphs = newGraphs;
    }
  }
});

export default slice.reducer;

//actions
const {
  fetchGraphsStart,
  fetchGraphsFailed,
  fetchGraphsSuccess,
  fetchGraphSuccess,
  updateGraphStart,
  updateGraphFailed,
  updateGraphSuccess,
  updateFilterSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchGraphs =
  (graphIds: string[]): AppThunk =>
  async dispatch => {
    if (graphIds.length > 0) {
      try {
        const ids = graphIds.map(id => `"${id}"`).toString();
        dispatch(fetchGraphsStart());
        const response: AxiosResponse<IGraph[]> = await axios.get(`${apiUrl}/graphs/?ids=[${ids}]`);
        dispatch(fetchGraphsSuccess(response.data));
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(fetchGraphsFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    } else {
      dispatch(fetchGraphsSuccess([]));
    }
  };

export const fetchGraph =
  (graphId: string): AppThunk =>
  async dispatch => {
    try {
      const ids = `"${graphId}"`;
      dispatch(fetchGraphsStart());
      const response: AxiosResponse<IGraph[]> = await axios.get(`${apiUrl}/graphs/?ids=[${ids}]`);
      dispatch(fetchGraphSuccess(response.data[0]));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchGraphsFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const createGraph =
  (graph: IGraph): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGraphStart());
      const response: AxiosResponse<IGraph> = await axios.post(`${apiUrl}/graphs/`, graph);
      dispatch(updateGraphSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGraphFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateGraph =
  (graph: Partial<IGraph>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGraphStart());
      const response: AxiosResponse<IGraph> = await axios.patch(
        `${apiUrl}/graphs/${graph.graph_id}/`,
        graph
      );
      dispatch(updateGraphSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGraphFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateGraphFilter =
  (graph: Partial<IGraph>): AppThunk =>
  async (dispatch, getState) => {
    try {
      await dispatch(updateGraph(graph));
      dispatch(updateFilterSuccess(getState().graphs.graph as IGraph));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGraphFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
