import { nanolikeDataType } from 'shared/model/api.model';
import { operatorType, valueType } from 'shared/model/graph.model';

export const criterionFormSeparator = '___';

export interface IFilterCriterionDef {
  key: nanolikeDataType | 'device_content_id' | 'group_ids';
  i18n?: string;
  operators: operatorType[];
  valueType: valueType;
  defaultValue: any;
}

const levelPercentDef: IFilterCriterionDef = {
  key: 'level_percent',
  operators: ['<=', '>='],
  valueType: 'number_positive',
  defaultValue: ''
};
const remainingDaysDef: IFilterCriterionDef = {
  key: 'remainingDays',
  operators: ['<=', '>='],
  valueType: 'number_positive',
  defaultValue: ''
};

export const temperatureDef: IFilterCriterionDef = {
  key: 'temperature',
  operators: ['<=', '>='],
  valueType: 'number',
  defaultValue: ''
};

export const deviceContentDef: IFilterCriterionDef = {
  key: 'device_content_id',
  i18n: 'device_content',
  operators: ['in', 'not in'],
  valueType: 'multiselect',
  defaultValue: []
};

export const groupDef: IFilterCriterionDef = {
  key: 'group_ids',
  i18n: 'group',
  operators: ['in', 'not in'],
  valueType: 'multiselect',
  defaultValue: []
};

export const missingWeightDef: IFilterCriterionDef = {
  key: 'missingWeight',
  i18n: 'missingWeight',
  operators: ['>=', '<='],
  valueType: 'number',
  defaultValue: ''
};

export const tableFilters = [levelPercentDef, remainingDaysDef];

export const mapFilters = [levelPercentDef, remainingDaysDef];
