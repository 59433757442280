import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from 'shared/model/user.model';
import { changeUserWorkspaceRole, IInviteUser, inviteUser } from 'shared/reducers/usersSlice';
import { isValidEmail } from 'shared/utils/data-utils';
import { usePrevious } from 'shared/utils/hook-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import { getLangOptions } from '../../../shared/utils/lang-utils';
import { IWorkspaceRole } from 'shared/model/workspace.model';
interface IFormResponse {
  email: string;
  role: ILabelValueOption;
  lang: ILabelValueOption;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {},
    content: {
      minHeight: '200px',
      minWidth: '350px'
    },
    email: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2)
    }
  })
);

interface AddOrEditUserDialogProps {
  user?: IUser;
  onSuccess?: Function;
}

const AddOrEditUserDialog = (props: AddOrEditUserDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const inviteSuccess = useSelector(({ users }: IRootState) => users.inviteSuccess);
  const inviting = useSelector(({ users }: IRootState) => users.inviting);
  const updating = useSelector(({ users }: IRootState) => users.inviting);
  const updateSuccess = useSelector(({ users }: IRootState) => users.updateSuccess);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const availableRoles = useSelector(({ workspace }: IRootState) => workspace.availableRoles);
  const previousInviteSuccess = usePrevious(inviteSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const { user, onSuccess } = props;
  const isNew = user ? false : true;
  const roleOptions = availableRoles.map((role: IWorkspaceRole) => {
    return {
      label: role.display_name,
      value: role.idRole
    };
  });
  const langOptions = getLangOptions();

  const form = useForm<IFormResponse>({
    defaultValues: {
      role: user?.workspace_role.idRole ?? '',
      email: user ? user.email : '',
      lang: langOptions.find(opt => opt.value === i18n.resolvedLanguage)
    }
  });

  const toggleDialog = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (previousInviteSuccess !== inviteSuccess && inviteSuccess === true && open) {
      setOpen(false);
    }
  }, [inviteSuccess, open, previousInviteSuccess]);

  useEffect(() => {
    if (previousUpdateSuccess !== updateSuccess && updateSuccess === true && open) {
      setOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [onSuccess, open, previousUpdateSuccess, updateSuccess]);

  const onSubmit = form.handleSubmit((values: IFormResponse) => {
    const invitation: IInviteUser = {
      email: values.email,
      workspace_role: values.role.value,
      group_admin_ids: [],
      group_user_ids: [],
      lang: values.lang.value
    };

    if (isNew) {
      dispatch(inviteUser(invitation));
    } else if (user) {
      const wsRole = {
        workspace_role_id: values.role.value,
        workspace_role_name: values.role.label
      };
      dispatch(changeUserWorkspaceRole(wsRole, user));
    }
  });

  const title = isNew ? t('users_actionbar') : t('edit_account');

  return (
    <>
      <Tooltip title={title}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          {isNew ? <PersonAddIcon /> : <EditIcon />}
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog} className={classes.dialog}>
        <FormContext {...form}>
          <form onSubmit={onSubmit} autoComplete="off">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.content} dividers>
              <TextField
                autoFocus
                className={classes.email}
                margin="normal"
                fullWidth
                label={t('email_address')}
                name="email"
                autoComplete="off"
                inputRef={form.register({
                  validate: value => {
                    if (!value || value.length === 0) {
                      return <Trans i18nKey="required_field">Required Field</Trans>;
                    } else if (!isValidEmail(value)) {
                      return <Trans i18nKey="email_check">invalid email</Trans>;
                    }
                    return true;
                  }
                })}
                disabled={inviting || !isNew}
                error={form.errors.email ? true : false}
                helperText={form.errors.email && form.errors.email.message}
              />
              <SingleAutoComplete
                name="role"
                label={t('role')}
                options={roleOptions}
                defaultValue={roleOptions.find(opt => opt.value === user?.workspace_role.idRole)}
                disableClearable
                disabled={inviting || updating}
                validate={(value: ILabelValueOption) => {
                  if (!value) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />
              <SingleAutoComplete
                options={langOptions}
                defaultValue={langOptions.find(opt => opt.value === i18n.resolvedLanguage)}
                disableClearable
                label={t('lang')}
                name="lang"
                disabled={inviting || !isNew}
                helperText={t('lang_invitation_helper_text')}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={toggleDialog}
                startIcon={<CancelIcon />}
                disabled={inviting || updating}
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={inviting || updating}
              >
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};

export default AddOrEditUserDialog;
