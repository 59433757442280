import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { Alert } from '@material-ui/lab';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';

export interface IConfirmDialog {
  open: boolean;
  onConfirm: Function;
  onClose: Function;
  title?: string;
  message: string;
  disabled?: boolean;
}

const ConfirmDialog = (props: IConfirmDialog) => {
  const { t } = useTranslation();
  const { title = t('confirm'), message, open, disabled = false } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <Box marginTop={2} marginBottom={1}>
          <Alert severity="warning">{t('uncombine_warning')}</Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="default"
          startIcon={<CancelIcon />}
          disabled={disabled}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          startIcon={<DoneIcon />}
          autoFocus
          disabled={disabled}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
