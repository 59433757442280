import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import moment, { Moment } from 'moment';
import React from 'react';
import NanolikeDatePicker from 'shared/widgets/form/nanolikeDatePicker';

const now = moment();
const availableMonths: Moment[] = [];
for (let i = 0; i < 12; i++) {
  availableMonths.push(now.subtract(1, 'month').clone());
}

export interface IKpiFormSecondStepProps {
  step: number;
  activeStep: number;
}

const KpiFormSecondStep = (props: IKpiFormSecondStepProps) => {
  const { activeStep, step } = props;

  return (
    <Box display={activeStep === step ? 'block' : 'none'} mt={4}>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <NanolikeDatePicker
            name="date"
            value={moment()}
            views={['month', 'year']}
            disableFuture
            format="MMMM YYYY"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KpiFormSecondStep;
