import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRuleTriggered } from 'shared/model/rule.model';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      minWidth: '180px'
    },
    icon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1)
    }
  })
);

export interface IRuleTriggeredProps {
  data: IRuleTriggered;
}

const RuleTriggered = (props: IRuleTriggeredProps) => {
  const triggered = props.data;
  const classes = useStyles();
  const { t } = useTranslation();

  if (!triggered) {
    return null;
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <Box p={2}>
        <Box mb={1} textAlign="center">
          <NotificationsActiveIcon color="primary" className={classes.icon} />
          {formatDate(triggered.triggered_at, APP_TIMESTAMP_FORMAT)}
        </Box>
        <Box>
          {triggered.offending_devices.map(aTriggeredDevice => {
            return (
              <Box key={aTriggeredDevice.device_id} textAlign="center">
                {t('triggeredDeviceInfo', {
                  device: aTriggeredDevice.device_name,
                  nb: aTriggeredDevice.offending_value
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
};

export default RuleTriggered;
