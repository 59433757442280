import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IConfirmDeleteDialogProps {
  open: boolean;
  onConfirm: Function;
  onClose: Function;
  title?: string;
  message?: string;
  objectToReturn: any[];
}

const ConfirmDeleteDialog = (props: IConfirmDeleteDialogProps) => {
  const { title, message, objectToReturn, open } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm(objectToReturn);
  };

  const finalTitle = title ? title : t('defaultTitle');
  const finalMessage = message
    ? message
    : objectToReturn.length === 1
    ? t('defaultSingleMessage')
    : t('defaultMultipleMessage', { nb: objectToReturn.length });

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>{finalTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{finalMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          variant="contained"
          color="default"
          startIcon={<CancelIcon />}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          autoFocus
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
