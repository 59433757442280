import AssignmentIcon from '@material-ui/icons/Assignment';
import React from 'react';
import { MenuItemProps } from 'shared/layout/menu/menuItem';
import { siloProductsTo } from '.';

const SiloProductMenu: MenuItemProps = {
  icon: <AssignmentIcon />,
  primary: 'products',
  to: siloProductsTo,
  resource: 'menu_products',
  operation: ['READ']
};

export default SiloProductMenu;
