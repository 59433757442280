import Box from '@material-ui/core/Box';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ILabelValueOption } from 'shared/utils/select-utils';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import AlertFormMap from './alertFormMap';

export interface ISelectLocationProps {
  step: number;
  activeStep: number;
}

const SelectLocation = (props: ISelectLocationProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const form = useFormContext();
  const isNew = id === 'new' ? true : false;

  const options: ILabelValueOption[] = [
    {
      label: t('in'),
      value: 'in'
    },
    { label: t('out'), value: 'out' }
  ];

  const defaultValue = !isNew ? form.getValues().geofencing_strategy : undefined;

  return (
    <Box>
      <SingleAutoComplete
        name="geofencing_strategy"
        label={t('select_alert_trigger')}
        options={options}
        defaultValue={defaultValue}
        disableClearable
        validate={(value: any) => {
          if (activeStep === step && !value) {
            return <Trans i18nKey="required_field">Required Field</Trans>;
          }
          return true;
        }}
      />

      <AlertFormMap />
    </Box>
  );
};

export default SelectLocation;
