import { Button } from '@material-ui/core';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { workspaceIsIbc } from 'shared/utils/workspace-utils';
import CalibrateDialog from './calibrateDialog';

interface ICalibrateBtnProps {
  device: IDevice;
  onSuccess: () => void;
}

const CalibrateBtn = (props: ICalibrateBtnProps) => {
  const { t } = useTranslation();

  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);

  const isIbc = workspaceIsIbc(settings);

  const { device } = props;
  const [open, setOpen] = useState(false);
  if (isIbc) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && <CalibrateDialog device={device} open={open} handleClose={handleClose} />}
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {t('calibrate')}
      </Button>
    </>
  );
};

export default CalibrateBtn;
