import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { Moment } from 'moment';
import { IRule } from 'shared/model/rule.model';
import { ISiloOrder } from 'shared/model/siloOrder.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { convertDateFromServer } from 'shared/utils/date-utils';
import { errorNotification } from './notifierSlice';

interface ISiloOrdersResponse {
  count: number;
  results: ISiloOrder[];
}

const initialState = {
  loading: false,
  errorMessage: '',
  siloOrders: [] as ISiloOrder[],
  siloOrder: null as ISiloOrder | null,
  updating: false,
  updateSuccess: false
};

export type SiloOrdersState = typeof initialState;

export const slice = createSlice({
  name: 'siloOrders',
  initialState,
  reducers: {
    fetchOrdersStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchOrdersFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchOrdersSuccess: (state, action: PayloadAction<ISiloOrder[]>) => {
      action.payload.forEach(item => {
        item.date = convertDateFromServer(item.date as string);
      });
      state.loading = false;
      state.siloOrders = action.payload;
    },
    updateOrderStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateOrderFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateOrderSuccess: (state, action: PayloadAction<ISiloOrder>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.siloOrder = action.payload;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchOrdersStart,
  fetchOrdersFailed,
  fetchOrdersSuccess,
  updateOrderStart,
  updateOrderFailed,
  updateOrderSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchOrders =
  (startDate: Moment): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchOrdersStart());
      const minDateStr = startDate.toISOString();
      const response: AxiosResponse<ISiloOrdersResponse> = await axios.get(
        `${apiUrl}/orders?source=delivery,export,external&minDate=${minDateStr}`
      );
      dispatch(fetchOrdersSuccess(response.data.results));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchOrdersFailed(errorMsg));
      dispatch(errorNotification(errorMsg));
    }
  };

export const createOrder =
  (rule: IRule): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateOrderStart());
      const response: AxiosResponse<ISiloOrder> = await axios.post(`${apiUrl}/orders/`, rule);
      dispatch(updateOrderSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateOrderFailed(errorMsg));
      dispatch(errorNotification(errorMsg));
    }
  };
