import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { IMe, IUser } from 'shared/model/user.model';
import { displayUserInitials } from 'shared/utils/user-utils';

const useStyles = makeStyles((theme: Theme) => {
  //@ts-ignore
  const avatarColor = theme.palette.primary.light;

  return createStyles({
    avatar: {
      color: theme.palette.primary.contrastText,
      background: avatarColor,
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: '0.8rem'
    }
  });
});

export interface IUserAvatarProps {
  user: IUser | IMe | undefined;
}

const UserAvatar = (props: IUserAvatarProps) => {
  const classes = useStyles();

  const { user } = props;

  if (!user) {
    return null;
  }

  return (
    <Avatar className={classes.avatar} data-tag="allowRowEvents">
      {displayUserInitials(user)}
    </Avatar>
  );
};

export default UserAvatar;
