import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PrivateComponent from 'shared/auth/privateComponent';
import { IGroup } from 'shared/model/group.model';
import { IProduct } from 'shared/model/product.model';
import { deleteGroupsFromProduct } from 'shared/reducers/productSlice';
import ConfirmDeleteDialog from 'shared/widgets/confirmDeleteDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

export interface IProductGroups {
  product: IProduct;
  group: IGroup;
}

const ProductGroups = (props: IProductGroups) => {
  const { product, group } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteGroup, setOpenDeleteGroup] = useState<boolean>(false);
  const onDeleteGroup = () => setOpenDeleteGroup(true);

  const onConfirmDelete = () => {
    dispatch(deleteGroupsFromProduct(group, product));
    setOpenDeleteGroup(false);
  };
  return (
    <PrivateComponent resource="DeviceContent" operation={['UPDATE']}>
      <Chip
        key={group.group_id}
        label={group.group_name}
        title={group.group_name}
        variant="outlined"
        className={classes.chip}
        size="small"
        onDelete={onDeleteGroup}
      />
      <ConfirmDeleteDialog
        open={openDeleteGroup}
        onConfirm={onConfirmDelete}
        onClose={() => setOpenDeleteGroup(false)}
        objectToReturn={product}
      />
    </PrivateComponent>
  );
};

export default ProductGroups;
