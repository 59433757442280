import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { ReactComponent as AddGraphIcon } from 'shared/icons/createGraph.svg';
import { fetchDashboard } from 'shared/reducers/dashboardSlice';
import Loading from 'shared/widgets/loading';
import DashboardToolbar from './dashboardToolbar';
import NanoDashboardGrid from './grid/NanoDashboardGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: 'white',
      width: '20px',
      height: '30px',
      marginRight: theme.spacing(1)
    }
  })
);

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const loading = useSelector(({ dashboard }: IRootState) => dashboard.loading);
  const dashboard = useSelector(({ dashboard }: IRootState) => dashboard.dashboard);

  const graphs = useSelector(({ graphs }: IRootState) => graphs.graphs);
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      dispatch(fetchDashboard(id));
    }
  }, [dispatch, id]);

  return (
    <Box>
      {loading ? (
        <Box p={4}>
          <Loading />
        </Box>
      ) : (
        <>
          {dashboard?.dashboard_id === id && (
            <>
              <DashboardToolbar />
              <Box overflow="auto" height="calc(100vh - 100px)">
                {graphs.length > 0 ? (
                  <NanoDashboardGrid />
                ) : (
                  <PrivateComponent resource="Graph" operation={['CREATE']}>
                    <Box pt={4} textAlign="center">
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/dashboard/${id}/graph/new`}
                        title={t('create_graph')}
                      >
                        <AddGraphIcon className={classes.icon} />
                        {t('create_graph')}
                      </Button>
                    </Box>
                  </PrivateComponent>
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Dashboard;
