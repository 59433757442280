import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRule, IRuleTriggered } from 'shared/model/rule.model';
import { convertDateFromServer } from 'shared/utils/date-utils';
import Loading from 'shared/widgets/loading';
import RuleTriggered from './ruleTriggered';

const apiUrl = getEnvApiUrl();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100vw - 90px)',
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    leftMenuOpen: {
      width: 'calc(100vw - 235px)'
    },
    ruleTitle: {
      fontWeight: 'bold',
      '&>svg': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
      }
    },
    triggeredList: {
      display: 'flex',
      flexDirection: 'row-reverse',
      overflowX: 'auto'
    }
  })
);

export interface IRuleDetailsProps {
  data?: IRule;
}

const RuleDetails = (props: IRuleDetailsProps) => {
  const rule = props.data;
  const classes = useStyles();
  const { t } = useTranslation();
  const leftMenuOpen = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IRuleTriggered[]>([]);

  useEffect(() => {
    const loadData = async (ruleId: string) => {
      setLoading(true);
      const response: AxiosResponse<IRuleTriggered[]> = await axios.get(
        `${apiUrl}/rules/${ruleId}/triggered/`
      );

      response.data.forEach(aTrigger => {
        aTrigger.triggered_at = convertDateFromServer(aTrigger.triggered_at);
      });

      const data = response.data;

      setData(data);
      setLoading(false);
    };
    if (rule) {
      loadData(rule.id);
    }
  }, [rule]);

  if (!rule) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, { [classes.leftMenuOpen]: leftMenuOpen })}>
      <Paper elevation={2}>
        {loading ? (
          <Box p={4}>
            <Loading />
          </Box>
        ) : (
          <Box minHeight="100px">
            {data.length === 0 ? (
              <Box textAlign="center" p={4}>
                {t('no_triggered_rules')}
              </Box>
            ) : (
              <Box py={2} pr={1} className={classes.triggeredList}>
                {data.map(aData => (
                  <RuleTriggered key={aData.triggered_id} data={aData} />
                ))}
              </Box>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default RuleDetails;
