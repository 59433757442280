import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IGroup } from 'shared/model/group.model';
import {
  IProduct,
  IProductCreate,
  IProductDelete,
  IProductPatch
} from 'shared/model/product.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  errorMessage: '',
  products: [] as IProduct[],
  product: null as IProduct | null,
  updating: false,
  updateSuccess: false
};

export type ProductState = typeof initialState;

export const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    fetchProductsStart: state => {
      state.loading = true;
      state.errorMessage = '';
    },
    fetchProductsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchProductsSuccess: (state, action: PayloadAction<IProduct[]>) => {
      state.loading = false;
      state.products = action.payload;
    },
    fetchProductSuccess: (state, action: PayloadAction<IProduct>) => {
      state.loading = false;
      state.product = action.payload;
    },
    updateProductStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateProductFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateProductSuccess: (state, action: PayloadAction<IProduct>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.product = action.payload;
    },
    deleteProductSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.product = null;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchProductsStart,
  fetchProductsFailed,
  fetchProductsSuccess,
  fetchProductSuccess,
  updateProductFailed,
  updateProductStart,
  updateProductSuccess,
  deleteProductSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchProducts = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchProductsStart());
    const response: AxiosResponse<IProduct[]> = await axios.get(
      `${apiUrl}/workspace/device-contents`
    );
    await dispatch(fetchProductsSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchProductsFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const fetchProduct =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchProductsStart());
      const response: AxiosResponse<IProduct> = await axios.get(
        `${apiUrl}/workspace/device-contents/${id}/`
      );
      dispatch(fetchProductSuccess(response.data));
    } catch (error) {
      console.log({ error });
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchProductsFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteProduct =
  (product: IProductDelete): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateProductStart());
      await axios.delete(`${apiUrl}/workspace/device-contents/${product.id}/`);
      dispatch(deleteProductSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateProductFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const createProduct =
  (product: IProductCreate): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateProductStart());
      const response: AxiosResponse = await axios.post(
        `${apiUrl}/workspace/device-contents/`,
        product
      );
      dispatch(updateProductSuccess(response.data));
      dispatch(fetchProducts());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateProductFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateProduct =
  (product: IProductPatch, fetch: boolean = true): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateProductStart());
      //@ts-ignore
      const response: AxiosResponse<IProduct> = await axios.patch(
        `${apiUrl}/workspace/device-contents/${product.id}/`,
        {
          ...product
        }
      );

      dispatch(updateProductSuccess(response.data));
      if (fetch) {
        dispatch(fetchProducts());
      }
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateProductFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteGroupsFromProduct =
  (group: IGroup, product: IProduct, fetch: boolean = true): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateProductStart());
      //@ts-ignore
      const response: AxiosResponse<IProduct> = await axios.patch(
        `${apiUrl}/workspace/device-contents/${product.id}/`,
        {
          group_ids: product.groups?.filter(g => g.group_id !== group.group_id).map(g => g.group_id)
        }
      );

      dispatch(updateProductSuccess(response.data));
      if (fetch) {
        dispatch(fetchProducts());
      }
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateProductFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
