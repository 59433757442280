import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MapIcon from '@material-ui/icons/Map';
import TableIcon from '@material-ui/icons/TableChart';
import CurveIcon from '@material-ui/icons/Timeline';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '100%',
      height: '32px'
    }
  })
);

export interface IGraphTypeProps {
  type: string;
  className?: string;
}

const GraphType: React.FunctionComponent<IGraphTypeProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { type, className } = props;

  return (
    <Box p={1} className={className}>
      <Box>
        {type === 'table' && <TableIcon className={classes.icon} />}
        {type === 'map' && <MapIcon className={classes.icon} />}
        {type === 'curve' && <CurveIcon className={classes.icon} />}
      </Box>
      <Box>{t(type)}</Box>
    </Box>
  );
};

export default GraphType;
