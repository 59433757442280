import { useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TermsOfUse from 'modules/home/termsOfUse';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeLeftMenu } from 'shared/reducers/layoutSlice';
import Routes from '../routes/routes';
import Header from './header/header';
import LeftMenu from './menu/menu';

export const HEADER_HEIGHT = 48;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%'
    }
  })
);

const Layout = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (isSmall) {
      dispatch(closeLeftMenu());
    }
  }, [dispatch, isSmall]);

  return (
    <>
      <TermsOfUse />
      <Header />
      <LeftMenu />
      <main className={classes.content}>
        <Routes />
      </main>
    </>
  );
};

export default Layout;
