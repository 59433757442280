import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IGraph } from 'shared/model/graph.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'auto',
      height: '100%'
    }
  })
);

export interface IErrorVizFallbackProps {
  graph: IGraph;
}

const ErrorVizFallback = (props: IErrorVizFallbackProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Alert severity="error" className={classes.root}>
      <AlertTitle>{t('error_rendering_visualization')}</AlertTitle>
      <Box>
        <pre>{JSON.stringify(props.graph, null, 2)}</pre>
      </Box>
    </Alert>
  );
};

export default ErrorVizFallback;
