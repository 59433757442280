import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { IRootState } from 'config/store';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useIsAuthorised } from './auth-utils';

interface IOwnProps extends RouteProps {
  resource: string;
  operation: string[];
}

export interface IPrivateRouteProps extends IOwnProps {}

export const PrivateRoute = ({
  component: Component,
  resource,
  operation,
  ...rest
}: IPrivateRouteProps) => {
  const authentication = useSelector(({ authentication }: IRootState) => authentication);
  const { isAuthenticated, sessionHasBeenFetched } = authentication;
  const isAuthorised = useIsAuthorised(resource, operation);

  const checkedComponent = (props: any) =>
    isAuthorised ? (
      // @ts-ignore
      <Component {...props} />
    ) : (
      <Box p={2}>
        <Alert severity="error">
          <Trans>error_unauthorzed_page</Trans>
        </Alert>
      </Box>
    );

  const renderRedirect = (props: any) => {
    if (!sessionHasBeenFetched) {
      return <div></div>;
    } else {
      return isAuthenticated ? (
        checkedComponent(props)
      ) : (
        <Redirect
          to={{
            pathname: '/',
            search: props.location.search,
            state: { from: props.location }
          }}
        />
      );
    }
  };

  if (!Component)
    throw new Error(
      `A component needs to be specified for private route for path ${(rest as any).path}`
    );

  return <Route {...rest} render={renderRedirect} />;
};

export default PrivateRoute;
