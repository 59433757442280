import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { APP_LOCAL_DATE_FORMAT } from 'shared/utils/date-utils';

export interface INanolikeDatePickerProps extends Partial<KeyboardDatePickerProps> {
  name: string;
  value: Moment | null;
  required?: boolean;
  format?: string;
}

const NanolikeDatePicker = (props: INanolikeDatePickerProps) => {
  const {
    name,
    required = false,
    value = null,
    format = APP_LOCAL_DATE_FORMAT,
    fullWidth = true,
    ...rest
  } = props;

  const [date, setDate] = useState<Moment>(value as Moment);
  const form = useFormContext();

  useEffect(() => {
    form.register({ name }, { required });
  }, [form, name, required]);

  const handleChange = (value: any) => {
    setDate(value);
    form.setValue(name, value);
  };

  return (
    <KeyboardDatePicker
      format={format}
      fullWidth={fullWidth}
      value={date}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default NanolikeDatePicker;
