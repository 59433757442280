import { Box } from '@material-ui/core';
import { TFunction } from 'i18next';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDataPointExtend, IGraphData, nanolikeDataType } from 'shared/model/api.model';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import GraphLevel from 'shared/widgets/visualization/graphLevel';

const deviceValueIsNotAvailable = '-';
const deviceIsNotAvailable = '-';

export const columnFormatter = (graphData: IGraphData, t: TFunction) => {
  const type = graphData.data_type as string;
  switch (type) {
    case 'lastMessageReceived':
      return dateFromNowFormatter(graphData);
    case 'fillingDate':
      return dateTimeColumnFormatter(graphData);
    case 'poi':
      return poiColumnFormatter(graphData);
    case 'levelJPlus1':
    case 'movement':
      return movementColumnFormatter(graphData, t);
    case 'levelJPlus2':
    case 'levelJPlus3':
    case 'levelJPlus4':
    case 'levelJPlus5':
    case 'levelJPlus6':
    case 'levelJPlus7':
    case 'level_percent':
    case 'levelJPlus1Percent':
    case 'levelJPlus2Percent':
    case 'levelJPlus3Percent':
    case 'levelJPlus4Percent':
    case 'levelJPlus5Percent':
    case 'levelJPlus6Percent':
    case 'levelJPlus7Percent':
      return simpleLevelFormatter(graphData);
    default:
      return defaultColumnFormatter(graphData, t);
  }
};

/**
 * helper to get the value in the row based on the type
 */
export const sortFunctionSelector = (rowA: any, rowB: any, selector: string | Function) => {
  if (typeof selector === 'function') return [selector(rowA), selector(rowB)];
  switch (selector) {
    case 'device_name':
    case 'previous_poi_name':
    case 'farm_name':
    case 'location':
    case 'movement':
    case 'device_content':
    case 'poi':
      return [rowA[selector], rowB[selector]];
    case 'level':
    case 'levelPredictions':
      return [rowA[selector]?.level_percent, rowB[selector]?.level_percent];
    default:
      return [rowA[selector]?.value, rowB[selector]?.value];
  }
};

/**
 * Function that sort the row compared to the other.
 * We force by default that undefined rows are at the bottom
 * @param rows
 * @param selector
 * @param direction
 * @returns {any[]}
 */
export const customSort = (rows: any[], selector: string | Function, direction: 'asc' | 'desc') => {
  return rows.sort((rowA, rowB) => {
    const [a, b] = sortFunctionSelector(rowA, rowB, selector);
    // equal items sort equally
    if (a === b) return 0;

    // nulls sort after anything else
    if (a == null) return 1;
    if (b == null) return -1;

    // Default ascendig a go after b
    const compare = a > b ? 1 : -1;
    return direction === 'asc' ? compare : -compare;
  });
};
const movementColumnFormatter = (graphData: IGraphData, t: TFunction) => (row: any) => {
  return row.movement.value == null ? '-' : t('daysWithCount', { count: row.movement.value });
};
const poiColumnFormatter = (graphData: IGraphData) => (row: any) => {
  return row.farm_name ?? '-';
};
const defaultColumnFormatter = (graphData: IGraphData, t: TFunction) => (row: any) => {
  if (row.device_status === 'ok') {
    const type = graphData.data_type as string;
    const value = row[type];
    return t('data_type_unit', { value: value });
  }
  return deviceIsNotAvailable;
};

const dateTimeColumnFormatter = (graphData: IGraphData) => (row: any) => {
  const value = row[graphData.data_type];
  if (row.device_status === 'ok') {
    if (!value.value) {
      return deviceValueIsNotAvailable;
    }
    return formatDate(value.value, APP_TIMESTAMP_FORMAT);
  }
  return deviceIsNotAvailable;
};

const dateFromNowFormatter = (graphData: IGraphData) => (row: any) => {
  if (row.device_status === 'ok') {
    const value = row[graphData.data_type];
    if (!value.value) {
      return deviceValueIsNotAvailable;
    }
    return moment(value.value).fromNow();
  }
  return deviceIsNotAvailable;
};

export const CompleteLevelFormatter = (props: { level?: IDataPointExtend; status: string }) => {
  const { t } = useTranslation();
  if (props.status === 'ok') {
    if (!isNaN(Number(props.level?.level_percent)) && props.level?.filling) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingY={0.5}
        >
          <GraphLevel levelPercent={props.level.level_percent} width={30} height={30} />
          <Box>{t('data_type_unit', { value: props.level.filling })}</Box>
        </Box>
      );
    }
  }
  return <>{deviceIsNotAvailable}</>;
};

const simpleLevelFormatter = (graphData: IGraphData) => (row: any) =>
  row.device_status === 'ok' ? (
    <GraphLevel levelPercent={row[graphData.data_type]} width={30} height={30} />
  ) : (
    deviceIsNotAvailable
  );

export const ibcDataTypeOrder: nanolikeDataType[] = [
  'level',
  'level_liter',
  'missingLiter',
  'missingLiterJPlus1',
  'missingLiterJPlus2',
  'missingLiterJPlus3',
  'missingLiterJPlus4',
  'missingLiterJPlus5',
  'missingLiterJPlus6',
  'missingLiterJPlus7',
  'level_percent',
  'temperature',
  'remainingDays',
  'lastMessageReceived'
];

export const siloDataTypeOrder: nanolikeDataType[] = [
  'level',
  'level_kg',
  'level_t',
  'level_percent',
  'levelJPlus1',
  'levelJPlus2',
  'levelJPlus3',
  'levelJPlus4',
  'levelJPlus5',
  'levelJPlus6',
  'levelJPlus7',
  'levelJPlus1Kg',
  'levelJPlus2Kg',
  'levelJPlus3Kg',
  'levelJPlus4Kg',
  'levelJPlus1Percent',
  'levelJPlus2Percent',
  'levelJPlus3Percent',
  'levelJPlus4Percent',
  'levelJPlus5Percent',
  'levelJPlus6Percent',
  'levelJPlus7Percent',
  'missingWeight',
  'missingWeightJPlus1',
  'missingWeightJPlus2',
  'missingWeightJPlus3',
  'missingWeightJPlus4',
  'fillingDate',
  'remainingDays',
  'capa_max',
  'lastMessageReceived'
];
