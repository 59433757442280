import { saveAs } from 'file-saver';
import { ILabelValueOption } from './select-utils';

export const contentToFile = (str: string, contentType: string, fileName: string) => {
  const blob = new Blob([str], { type: contentType });
  saveAs(blob, fileName);
};

export const isValidEmail = (email: string) => {
  if (email.length > 64) {
    return false;
  }

  // eslint-disable-next-line
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/.test(email);
};

export const isFloat = (n: any) => {
  return Number(n) === n && n % 1 !== 0;
};

export const replaceDecimalSeparator = (num: number, oldSep: string, newSep: string) => {
  return num.toString().replace(oldSep, newSep);
};

export const sortByString = (fieldName: string) => (o1: any, o2: any) => {
  const str1 = o1[fieldName]?.toLowerCase();
  const str2 = o2[fieldName]?.toLowerCase();
  return str1.localeCompare(str2);
};

export const sortByName = sortByString('name');
export const sortByLabel = sortByString('label');

export const sortOptionsByLabel = (o1: ILabelValueOption, o2: ILabelValueOption) => {
  const l1 = o1.label.toLowerCase();
  const l2 = o2.label.toLowerCase();

  return l1.localeCompare(l2);
};

export const isNumeric = (str: any) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const isLocationCovered = (margins: Array<number>) => {
  if (Array.isArray(margins) && margins.length && !isNaN(margins[0])) return margins[0] >= 25;
  return false;
};
