import { TFunction } from 'i18next';
import isNumber from 'lodash/isNumber';
import { IDataPointPositionValue, IGraphData, nanolikeDataType } from 'shared/model/api.model';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import { getDeviceIcon } from 'shared/widgets/map/mapMarker';
import { getLevelInfo } from 'shared/widgets/visualization/graphLevel';

const distanceTolerance = 1;

export const onMarkerClusterClick = (
  t: TFunction,
  e: any,
  graphDatas: IGraphData[],
  day: number
): google.maps.InfoWindow => {
  const center = e.getCenter();
  const bounds = e.getBounds();
  const map = e.getMap();
  const clickedMarkers = e.getMarkers();

  const iw = new google.maps.InfoWindow();

  let allMarkersSameSpot = true;

  let lastPosition = null as any;
  clickedMarkers.forEach((marker: any) => {
    if (allMarkersSameSpot) {
      const position = marker.getPosition();

      let distance = 0;
      if (lastPosition) {
        distance = google.maps.geometry.spherical.computeDistanceBetween(lastPosition, position);
        lastPosition = position;
      } else {
        lastPosition = position;
      }

      if (distance > distanceTolerance) {
        allMarkersSameSpot = false;
      }
    }
  });

  if (allMarkersSameSpot) {
    let content = `<div style="margin-top:0.2rem;margin-bottom:0.5rem">${t(
      'devices_same_history_location',
      {
        number: `${clickedMarkers.length}`
      }
    )}</div>`;

    clickedMarkers.forEach((marker: any) => {
      const markerData = graphDatas.filter(
        markerGraphData => markerGraphData.device_id === marker.id
      );
      const markerContent = getMarkerContent(markerData, day, t);
      content += markerContent;
    });
    iw.setContent(content);

    const marker = new google.maps.Marker({
      position: center,
      map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10
      },
      opacity: 0
    });
    iw.open(map, marker);
    google.maps.event.addListener(iw, 'closeclick', () => {
      marker.setMap(null);
    });
    map.setCenter(center);
    map.setZoom(14);
  } else {
    // Zoom into the cluster
    map.fitBounds(bounds);
  }
  return iw;
};

export const getDeviceDataType = (data: IGraphData[], type: nanolikeDataType, deviceId: string) => {
  return data.find(aData => aData.device_id === deviceId && aData.data_type === type);
};

export const getMarkerContent = (data: IGraphData[], day: number, t: TFunction) => {
  let markerContent = '';
  const positionData = data.filter(aData => aData.data_type === 'position');
  if (positionData.length === 0) {
    return '';
  }

  const levelDayPercent =
    day > 0 ? (`levelJPlus${day}Percent` as nanolikeDataType) : 'level_percent';

  positionData.forEach(aData => {
    let content = `<div style="margin-bottom:0.5rem;min-width:100px;"><div style="font-weight:bold;margin-bottom:0.2rem;">${aData.device_name}</div>`;
    if (aData.is_silo) content += '\n' + aData.farm_name;
    if (['calibrating', 'pending'].includes(aData.status)) {
      content += '<div style="color:#2E48A7">' + t('devices_status.pending.label') + '</div>';
    } else if (
      ['problem', 'error', 'calibration_problem', 'level_problem'].includes(aData.status)
    ) {
      content += '<div style="color:#E83E6D">' + t('devices_status.error.label') + '</div>';
    } else {
      const deviceLevelData = aData.is_silo
        ? getDeviceDataType(data, 'level_t', aData.device_id)
        : getDeviceDataType(data, levelDayPercent, aData.device_id);
      const markerColor = getLevelColor(data, levelDayPercent, aData.device_id);
      if (deviceLevelData) {
        const deviceDataLevelPercent = getDeviceDataType(data, levelDayPercent, aData.device_id);
        const locationValue = aData.data_points[0].value as IDataPointPositionValue;
        const iconInfo = getDeviceIcon(
          deviceLevelData.status,
          deviceDataLevelPercent?.data_points[0].value as number,
          locationValue.position_radius === 0,
          aData.is_silo,
          aData.is_tank
        );
        content += `<div style="display:flex;align-items: center;justify-content: space-between;"><div style="color:#${markerColor}">${t(
          'level'
        )} ${t('data_type_unit', {
          value: deviceLevelData.data_points[0]
        })}</div><div><img height="42" src="${iconInfo.url}"/></div></div>`;
      }

      const deviceTimeData = getDeviceDataType(data, 'lastMessageReceived', aData.device_id);
      const time = deviceTimeData ? deviceTimeData.data_points[0].value : undefined;
      if (deviceTimeData && time) {
        content += `<div>${t('lastMessageReceived')} ${formatDate(
          time as string,
          APP_TIMESTAMP_FORMAT
        )}</div>`;
      }
      if (aData.configuration?.asset_type === 'mobile') {
        content += `<br><div>${t('lastGeolocation')} ${formatDate(
          aData.data_points[0].timestamp,
          APP_TIMESTAMP_FORMAT
        )}</div>`;
      }
    }
    content += '</div>';
    markerContent += content;
  });
  return markerContent;
};

const getLevelColor = (data: IGraphData[], levelDayPercent: nanolikeDataType, deviceId: string) => {
  const deviceLevelData = getDeviceDataType(data, levelDayPercent, deviceId);
  const level = deviceLevelData ? deviceLevelData.data_points[0].value : undefined;
  if (isNumber(level)) {
    return getLevelInfo(level).mapColorKey;
  }
  return 'cccccc';
};
