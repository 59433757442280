import ReactGA from 'react-ga4';

/**
 * Init Google Analytics
 */
const initAnalytics = (): void => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-HHCRJ3QZHQ');
  }
};

/**
 * Set user properties for the session in Google Analytics
 * @param {string} userId - The unique identifier for a user
 * @param {string} workspace - The name of the workspace
 * @param {string} role - The role of the user within the workspace
 */
const setUserProperties = (userId: string, workspace: string, role: string): void => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.gtag('set', 'user_properties', {
      user_id: userId,
      workspace: workspace?.toLowerCase(),
      role: role?.toLowerCase()
    });
  }
};

/**
 * Send an event to Google Analytics
 * @param {string} name - Name of the event
 * @param {any} params - Parameters of the event
 */
const sendEvent = (name: string, params: Record<string, any>): void => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event(name, params);
  }
};

export { initAnalytics, setUserProperties, sendEvent };
