import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotification } from 'shared/reducers/notifierSlice';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import KpiFormFirstStep from './kpiFormFirstStep';
import KpiFormSecondStep from './kpiFormSecondtStep';
import { IRootState } from 'config/store';
// import { IRootState } from 'config/store';

export type kpiType = 'use' | 'month' | 'rotation' | 'history';

export const kpiTypes: kpiType[] = ['use', 'month', 'rotation', 'history'];

const apiUrl = getEnvApiUrl();

export interface IKpiFormResponse {
  type: kpiType;
  date: Moment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem',
      width: '50vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    content: {
      width: '100%',
      minHeight: '40vh'
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    }
  })
);

export interface IKpiFormProps {
  onSubmit: (responses: IKpiFormResponse) => void;
}

const KpiForm = (props: IKpiFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const dispatch = useDispatch();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const kpiurl = settings.find(s => s.key === 'kpiurl')?.value;
  const steps = [t('step1'), t('step2')];

  const form = useForm<IKpiFormResponse>({
    mode: 'onChange',
    defaultValues: {
      type: 'use'
    }
  });

  const onSubmit = form.handleSubmit(responses => {
    let load = async () => console.error('MUST BE DEFINED');
    if (responses.type === 'use') {
      const date = responses.date ? responses.date : moment();
      const month = date.format('MM');
      const year = date.format('YYYY');

      const monthForApi = date.month() + 1;

      load = async () => {
        try {
          const res: AxiosResponse = await axios.post(`${apiUrl}/kpis/stats`, {
            responseType: 'blob',
            data: {
              month: monthForApi.toString(),
              year: date.year().toString()
            }
          });

          const headers = `"${t('csv_stats_id')}","${t('csv_stats_filledAtFactorysRatio')}","${t(
            'csv_stats_filledAtCustomersRatio'
          )}","${t('csv_stats_usageRatio')}","${t('csv_stats_emptyAtCustomersRatio')}","${t(
            'csv_stats_emptyAtFactorysRatio'
          )}"\n`;
          const url = window.URL.createObjectURL(new Blob([headers + res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `ibc_stats_${month}_${year}.csv`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          const errorMsg = getRequestErrorMessage(error);
          dispatch(errorNotification(errorMsg));
        }
      };
    }
    if (responses.type === 'history') {
      const date = responses.date ? responses.date : moment();
      const month = date.format('MM');
      const year = date.format('YYYY');

      const monthForApi = date.month() + 1;

      load = async () => {
        try {
          const res: AxiosResponse = await axios.post(`${apiUrl}/kpis/time-at-client`, {
            responseType: 'blob',
            data: {
              month: monthForApi.toString(),
              year: date.year().toString()
            }
          });

          // const headers = `"${t('csv_stats_id')}","${t('csv_stats_filledAtFactorysRatio')}","${t(
          //   'csv_stats_filledAtCustomersRatio'
          // )}","${t('csv_stats_usageRatio')}","${t('csv_stats_emptyAtCustomersRatio')}","${t(
          //   'csv_stats_emptyAtFactorysRatio'
          // )}"\n`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `time_at_client_in_days_${month}_${year}.csv`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          const errorMsg = getRequestErrorMessage(error);
          dispatch(errorNotification(errorMsg));
        }
      };
    }
    load();
  });

  const handleNext = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };
  return kpiurl ?? false ? (
    <iframe
      title="embeddedkpis"
      src={kpiurl}
      frameBorder="0"
      width="100%"
      height="100%"
      allowTransparency
    ></iframe>
  ) : (
    <FormContext {...form}>
      <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
        <Box className={classes.title}>
          <Typography variant="h5">{t('title')}</Typography>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.content}>
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box>
              <KpiFormFirstStep step={0} activeStep={activeStep} />
              <KpiFormSecondStep step={1} activeStep={activeStep} />
            </Box>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
            disabled={activeStep === 0}
          >
            {t('prev')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            endIcon={<ArrowForwardIcon />}
            disabled={activeStep === steps.length - 1}
          >
            {t('next')}
          </Button>
          <Button
            type="submit"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={activeStep !== steps.length - 1}
            variant="contained"
          >
            {t('export')}
          </Button>
        </Box>
      </form>
    </FormContext>
  );
};

export default KpiForm;
