import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceBatteryStatusFilter } from 'shared/model/device.model';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    statusFilter: {
      height: '29px',
      minWidth: '100%',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        minWidth: '10rem',
        marginLeft: theme.spacing(2)
      }
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

interface IBatteryFilterProps {
  status: DeviceBatteryStatusFilter;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const BatteryFilter = (props: IBatteryFilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { status, onChange } = props;
  return (
    <Select
      labelId="device-battery-status-select-filter"
      value={status}
      onChange={onChange}
      className={classes.statusFilter}
    >
      <MenuItem value="null">
        <span className={classes.empty}>{t('battery_level')}</span>
      </MenuItem>
      <MenuItem value="high">
        <div className={classes.menuItem}>
          <div>{t('battery_full')}</div>
        </div>
      </MenuItem>

      <MenuItem value="medium">
        <div className={classes.menuItem}>{t('battery_half_full')}</div>
      </MenuItem>

      <MenuItem value="low">
        <div className={classes.menuItem}>{t('battery_almost_empty')}</div>
      </MenuItem>
      <MenuItem value="empty">
        <div className={classes.menuItem}>{t('battery_empty')}</div>
      </MenuItem>
    </Select>
  );
};

export default BatteryFilter;
