import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthorised } from 'shared/auth/auth-utils';
import PrivateComponent from 'shared/auth/privateComponent';
import { IUser, IUserGroupMembership } from 'shared/model/user.model';
import { deleteGroupsFromUser } from 'shared/reducers/usersSlice';
import ConfirmDeleteDialog from 'shared/widgets/confirmDeleteDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

export interface IUserGroupMemberShipProps {
  groupMembership: IUserGroupMembership;
  user: IUser;
  readonly?: boolean;
}

const UserGroupMemberShip = (props: IUserGroupMemberShipProps) => {
  const classes = useStyles();
  const updating = useSelector(({ users }: IRootState) => users.updating);
  const dispatch = useDispatch();

  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const { groupMembership, user, readonly = false } = props;

  const canPromote = useIsAuthorised('User', ['UPDATE']);

  const tryDelete = () => {
    setOpenDelete(true);
  };

  const onConfirmDelete = () => {
    dispatch(deleteGroupsFromUser(groupMembership, user));
    setOpenDelete(false);
  };

  const onCloseConfirmDeleteDialog = () => {
    setOpenDelete(false);
  };

  const readOnlyChip = (
    <Chip
      label={groupMembership.group.group_name}
      title={groupMembership.group.group_name}
      variant="outlined"
      className={classes.chip}
      size="small"
    />
  );

  if (readonly) {
    return readOnlyChip;
  }

  return (
    <PrivateComponent resource="Group" operation={['UPDATE']} otherwise={readOnlyChip}>
      <Chip
        label={groupMembership.group.group_name}
        title={groupMembership.group.group_name}
        variant="outlined"
        className={classes.chip}
        size="small"
        disabled={updating}
        onDelete={canPromote ? tryDelete : undefined}
      />
      <ConfirmDeleteDialog
        open={openDelete}
        onConfirm={onConfirmDelete}
        onClose={onCloseConfirmDeleteDialog}
        objectToReturn={[groupMembership]}
      />
    </PrivateComponent>
  );
};

export default UserGroupMemberShip;
