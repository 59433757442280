import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import config from 'config/config';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import { graphType, graphTypes } from 'shared/model/graph.model';
import GraphType from 'shared/widgets/visualization/graphType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    toggleButton: {
      height: '100%',
      width: '100px',
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    buttonGroup: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    icon: {
      width: '100%',
      height: '32px'
    }
  })
);

export interface IGraphFormFirstStepProps {
  step: number;
  activeStep: number;
}

const GraphFormFirstStep = (props: IGraphFormFirstStepProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const form = useFormContext();
  const classes = useStyles();

  const [graphType, setGraphType] = useState<graphType>(form.getValues().graphType);

  useEffect(() => {
    form.register(
      { name: 'graphType' },
      {
        validate: value => {
          if (activeStep === step && !value) {
            return false;
          }
          return true;
        }
      }
    );
  }, [activeStep, form, graphType, step]);

  const onGraphTypeChange = (e: any, selection: any) => {
    form.setValue('graphType', selection);
    setGraphType(selection);
  };

  const getTooltip = (graphType: graphType) => {
    const graphConfig = config.graphTypes[graphType];

    return (
      <Box>
        <Box>{t('max_devices', { value: graphConfig.maxDevices })}</Box>
        <Box>{t('max_data_types', { value: graphConfig.maxTypes })}</Box>
      </Box>
    );
  };

  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      <Box display="flex" justifyContent="center" pt={6} pb={2}>
        <ToggleButtonGroup
          value={graphType}
          exclusive
          onChange={onGraphTypeChange}
          className={classes.buttonGroup}
        >
          {graphTypes.map(aGraphType => {
            return (
              <ToggleButton value={aGraphType} className={classes.toggleButton} key={aGraphType}>
                <Tooltip title={getTooltip(aGraphType)} arrow key={aGraphType}>
                  <Box>
                    <GraphType type={aGraphType} />
                  </Box>
                </Tooltip>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Box>
      <Box>
        {form.errors && form.errors.graphType && (
          <Alert severity="error">{t('required_field')}</Alert>
        )}
      </Box>
    </Box>
  );
};

export default GraphFormFirstStep;
