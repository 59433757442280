import { IDevice } from 'shared/model/device.model';

export const getDeviceLabel = (device: IDevice, showFarmName: boolean) => {
  if (showFarmName && device.farm_name && device.farm_name.length > 0) {
    return device.farm_name + ' - ' + device.device_name;
  }
  return device.device_name;
};

export const OKSTATUS = ['ok', 'level_problem'];
