import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from 'shared/model/user.model';
import { reInviteUser } from 'shared/reducers/usersSlice';

interface ISendInvitationProps {
  user: IUser;
  onSuccess: () => void;
}

const SendInvitation = (props: ISendInvitationProps) => {
  const { user } = props;
  const { t } = useTranslation();
  const inviting = useSelector(({ users }: IRootState) => users.inviting);
  const dispatch = useDispatch();

  if (!user.is_invitation_pending) {
    return null;
  }

  const onClick = async () => {
    dispatch(reInviteUser(user));
    props.onSuccess();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={onClick}
      disabled={inviting}
      title={t('user_invitation_resend')}
    >
      <SendIcon />
    </Button>
  );
};

export default SendInvitation;
