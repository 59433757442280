import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { IRootState } from 'config/store';
import useConfigTheme from 'config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openHeaderMenu, toggleLeftMenu } from 'shared/reducers/layoutSlice';
import { displayUser } from 'shared/utils/user-utils';
import UserAvatar from 'shared/widgets/userAvatar';
import HeaderMenu from './headerMenu';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    menu: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2)
      }
    },
    logo: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    },
    userInfo: {
      fontSize: '0.9rem'
    },
    userRole: {
      color: theme.palette.grey[400]
    }
  });
});

const Header = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector(({ authentication }: IRootState) => authentication.me);
  const configTheme = useConfigTheme();

  const toHome = () => {
    history.push('/');
  };

  const toggleMenu = () => {
    dispatch(toggleLeftMenu());
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(openHeaderMenu(event.currentTarget));
  };
  const userRole = me?.workspace_role.display_name;
  return (
    <AppBar position="fixed" style={{ backgroundColor: configTheme.headerMenuBackgroundColor }}>
      <Toolbar disableGutters className={classes.menu}>
        <Hidden smUp>
          <Button size="small" color="inherit" onClick={toggleMenu}>
            <MenuIcon />
          </Button>
        </Hidden>
        <Box className={classes.title} onClick={toHome}>
          <Hidden xsDown>
            <img
              src={configTheme.headerMenuLogo}
              alt="Nanolike"
              height="34"
              className={classes.logo}
            />
          </Hidden>
          <Typography variant="h6" noWrap>
            {configTheme.headerMenuTitle}
          </Typography>
        </Box>
        <Hidden xsDown>
          {me && (
            <Box display="flex" className={classes.userInfo} alignItems="center">
              <Box mr={1}>
                <UserAvatar user={me} />
              </Box>
              <Box>
                {displayUser(me)}
                <Typography variant="caption" display="block" className={classes.userRole}>
                  {userRole ? t(userRole?.toLowerCase()) : ''}
                </Typography>
              </Box>
              <Button color="inherit" onClick={handleMenuClick}>
                <MenuIcon />
              </Button>
            </Box>
          )}
          <HeaderMenu />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
