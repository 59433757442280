import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { hideChat } from 'shared/utils/chat.utils';

export interface ISettings {
  platform_title: string;
  useCase: string;
  primary: string;
  logo: string;
  logo_small: string;
  welcome_text: string;
  welcome_description: string;
  config_activated: boolean;
  background_login_color: string;
  background_header_color: string;
  settingV2: null | 'partial' | 'total';
}

const initialState = {
  loading: false,
  errorMessage: '',
  fetchSettingsSuccess: false,
  settings: null as ISettings | null
};

export type SettingsState = typeof initialState;

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    fetchSettingsStart: state => {
      state.loading = true;
      state.fetchSettingsSuccess = false;
    },
    fetchSettingsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.fetchSettingsSuccess = true;
      state.settings = null;
    },
    fetchSettingsSuccess: (state, action: PayloadAction<ISettings | null>) => {
      state.loading = false;
      state.fetchSettingsSuccess = true;
      state.settings = action.payload;
    },
    resetWorkspace: state => {
      state.loading = false;
      state.fetchSettingsSuccess = false;
      state.errorMessage = '';
      state.settings = null;
    }
  }
});

export default slice.reducer;

//Actions
const { fetchSettingsStart, fetchSettingsFailed, fetchSettingsSuccess } = slice.actions;
export const { resetWorkspace } = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchSettings = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchSettingsStart());
    const response: AxiosResponse<ISettings> = await axios.get(`${apiUrl}/public/config`);
    const data = response.data;
    if (data?.config_activated) {
      hideChat();
    }
    dispatch(fetchSettingsSuccess(data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchSettingsFailed(errorMsg));
  }
};
