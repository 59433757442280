import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import logo from 'shared/layout/header/logo.png';
import { IMe } from 'shared/model/user.model';
import { closeTermsOfUse, openTermsOfUse } from 'shared/reducers/layoutSlice';
import { errorNotification } from 'shared/reducers/notifierSlice';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    logo: {
      marginRight: theme.spacing(1),
      verticalAlign: 'middle',
      height: '32px'
    },
    content: {
      marginBottom: theme.spacing(2)
    },
    actions: {
      justifyContent: 'center'
    }
  })
);

const apiUrl = getEnvApiUrl();

const TermsOfUse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isOpen = useSelector(({ layout }: IRootState) => layout.termsOfUseOpen);
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const [checked, setChecked] = useState<boolean>(false);

  const terms: any = i18n.services.resourceStore.data.en.translation;

  useEffect(() => {
    if (me && !me.is_terms_accepted) {
      dispatch(openTermsOfUse());
    }
  }, [me, dispatch]);

  const handleClose = () => {
    const acceptTerms = async () => {
      try {
        await axios.post(`${apiUrl}/users/accept-terms/`);
        dispatch(closeTermsOfUse());
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(errorNotification(`${errorMsg}`));
      }
    };
    if (me.is_terms_accepted) {
      dispatch(closeTermsOfUse());
    } else if (checked) {
      acceptTerms();
    }
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const canClose = () => {
    if (me) {
      if (me.is_terms_accepted) {
        return true;
      }
    }
    return checked;
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.title}>
        <img src={logo} alt="Nanolike" className={classes.logo} />
        {t('terms_title')}
      </DialogTitle>
      <DialogContent>
        {terms &&
          Object.entries(terms.captions).map(([key, caption]: [any, any]) => (
            <Box key={key} className={classes.content}>
              <Typography align="left" variant="h6">
                {t(`captions.${key}.title`)}
              </Typography>

              {Object.keys(caption.paragraphs).map(pk => (
                <Typography key={pk} variant="inherit" paragraph>
                  {t(`captions.${key}.paragraphs.${pk}`)}
                </Typography>
              ))}
            </Box>
          ))}
        {me && !me.is_terms_accepted && (
          <Box textAlign="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChecked}
                  name="checked"
                  color="primary"
                />
              }
              label={t('agree')}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          autoFocus
          color="primary"
          variant="contained"
          onClick={handleClose}
          startIcon={<DoneIcon />}
          size="medium"
          disabled={!canClose()}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfUse;
