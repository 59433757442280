import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ruleTypes } from 'shared/model/rule.model';
import RuleIcon from '../ruleIcons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    toggleButton: {
      height: '100%',
      width: '135px',
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    buttonGroup: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    icon: {
      width: '100%',
      height: '32px'
    }
  })
);

export interface IRuleFormFirstStepProps {
  step: number;
  activeStep: number;
}

const RuleFormFirstStep = (props: IRuleFormFirstStepProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useFormContext();
  const [resourcetype, setResourceType] = useState<string>(form.getValues().resourcetype);

  useEffect(() => {
    form.register(
      { name: 'resourcetype' },
      {
        validate: value => {
          if (activeStep === step && !resourcetype) {
            return false;
          }
          return true;
        }
      }
    );
  }, [activeStep, form, step, resourcetype]);

  const onRuleTypeChange = (e: any, selection: any) => {
    form.setValue('resourcetype', selection);
    setResourceType(selection);
  };

  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      <Box display="flex" justifyContent="center" pt={6} pb={2}>
        <ToggleButtonGroup
          value={resourcetype}
          exclusive
          onChange={onRuleTypeChange}
          className={classes.buttonGroup}
        >
          {ruleTypes.map(ruleType => {
            return (
              <ToggleButton value={ruleType} key={ruleType} className={classes.toggleButton}>
                <Box display="flex" flexDirection="column" p={1}>
                  <Box>
                    <RuleIcon type={ruleType} />
                  </Box>
                  <Box>{t(ruleType)}</Box>
                </Box>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Box>
      <Box>
        {form.errors && form.errors.resourcetype && (
          <Alert severity="error">{t('required_field')}</Alert>
        )}
      </Box>
    </Box>
  );
};

export default RuleFormFirstStep;
