import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGroupsDevices } from 'shared/auth/auth-utils';
import { sortOptionsByLabel } from 'shared/utils/data-utils';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import MultipleAutoComplete from './multipleAutocomplete';

export interface ISelectDevicesProps {
  name: string;
  label: string;
  disabled: boolean;
  required?: boolean;
  step?: number;
  activeStep?: number;
  onSelectionChange?: Function;
  selectAll?: boolean;
}

const SelectDevices = (props: ISelectDevicesProps) => {
  const {
    activeStep,
    step,
    name,
    label,
    disabled,
    onSelectionChange,
    selectAll = false,
    required = false
  } = props;
  const { t } = useTranslation();
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const form = useFormContext();

  const deviceGroup = t('device', { count: 100 });

  const [devicesSelection, setDevicesSelection] = useState<ILabelValueOption[]>([]);

  const values = (form.getValues()[name] ? form.getValues()[name] : []) as ILabelValueOption[];

  useEffect(() => {
    if (values.length !== devicesSelection.length) {
      setDevicesSelection(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.length]);

  const devices = getGroupsDevices(allGroups);

  const devicesOptions = devices.map(aDevice => ({
    label: getDeviceLabel(aDevice, isSilo),
    value: aDevice.device_id,
    group: deviceGroup
  }));
  devicesOptions.sort(sortOptionsByLabel);

  const allDeviceSelected = devicesOptions.every(deviceOption => {
    return devicesSelection.some(deviceSelection => deviceSelection.value === deviceOption.value);
  });

  const onSelectAllChange = () => {
    const newDeviceSelection = (allDeviceSelected ? [] : devicesOptions) as ILabelValueOption[];
    setDevicesSelection(newDeviceSelection);

    form.setValue(name, newDeviceSelection, true);
    if (onSelectionChange) {
      onSelectionChange(newDeviceSelection);
    }
  };

  const defaultOptions = devicesOptions.filter(deviceOption => {
    const values = form.getValues(name) as ILabelValueOption[];
    return values ? values.some(aValue => aValue.value === deviceOption.value) : false;
  });

  return (
    <Box>
      <MultipleAutoComplete
        name={name}
        label={label}
        defaultValue={defaultOptions}
        options={devicesOptions}
        onSelectionChange={onSelectionChange}
        validate={(value: any) => {
          if (activeStep === step && !value && required) {
            return <Trans i18nKey="required_field">Required Field</Trans>;
          }
          return true;
        }}
        disabled={disabled}
      />
      {selectAll && (
        <Box display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Checkbox checked={allDeviceSelected} onChange={onSelectAllChange} size="small" />
            }
            label={t('select_all_devices')}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
};

export default SelectDevices;
