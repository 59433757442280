import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { updateDevice } from 'shared/reducers/devicesSlice';
import { ILabelValueOption } from 'shared/utils/select-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import NanolikeDatePicker from 'shared/widgets/form/nanolikeDatePicker';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import { FormControlLabel, ListItemText } from '@material-ui/core';
interface IFormResponse {
  groups: ILabelValueOption[];
  device_name: string;
  device_description: string;
  device_content: ILabelValueOption;
  device_install_date: Moment;
  device_fabrication_date: Moment;
  device_next_maintenance_date: Moment;
  galileou_enabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      minWidth: '580px',
      minHeight: '200px',
      '&>*': {
        marginBottom: theme.spacing(2)
      }
    }
  })
);

export interface IEditDeviceDialogProps {
  device: IDevice;
  onSuccess?: Function;
}

const EditDeviceDialog = (props: IEditDeviceDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  let deviceContents = useSelector(({ workspace }: IRootState) => workspace.deviceContents);
  const updateSuccess = useSelector(({ devices }: IRootState) => devices.updateSuccess);
  const updating = useSelector(({ devices }: IRootState) => devices.updating);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);

  const { device, onSuccess } = props;
  const groupOptions: ILabelValueOption[] = groups.map(aGroup => ({
    label: aGroup.group_name,
    value: aGroup.group_id
  }));

  const defaultDeviceContent = deviceContents.find(dc => dc.id === device.device_content_id);

  // Case where contents displayed must belong to the device group, we reuse content from store instead of loading again from API.
  if (settings.find(s => s.key === 'filterContentByGroup')?.value === 'true') {
    deviceContents = deviceContents.filter(d =>
      d.groups.map(g => g.group_id).some(g => device.group_ids?.includes(g))
    );
  }
  const deviceContentsOptions: ILabelValueOption[] = deviceContents.map(dc => ({
    label: dc.device_content,
    value: dc.id
  }));

  const defaultDeviceContentOption = defaultDeviceContent
    ? { label: defaultDeviceContent?.device_content, value: defaultDeviceContent?.id }
    : undefined;

  const defaultValue = device.group_ids
    ? device.group_ids
        .map(aGroupId => {
          return groupOptions.find(groupOption => groupOption.value === aGroupId);
        })
        .filter(aValue => aValue != null)
    : [];

  const form = useForm<IFormResponse>({
    defaultValues: {
      device_name: props.device.device_name,
      device_description: props.device.device_description,
      groups: defaultValue as ILabelValueOption[],
      device_content: defaultDeviceContentOption,
      galileou_enabled: props.device.galileou_enabled
    }
  });
  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (updateSuccess === true && open) {
      if (onSuccess) {
        onSuccess();
      }
      setOpen(false);
    }
  }, [onSuccess, open, updateSuccess]);

  const onSubmit = form.handleSubmit(values => {
    const group_ids = values?.groups?.map(aGroup => aGroup.value as string);

    const payload: Partial<IDevice> = {
      device_id: device.device_id,
      group_ids,
      device_name: values.device_name,
      device_description: values.device_description,
      device_install_date: values.device_install_date,
      device_fabrication_date: values.device_fabrication_date,
      device_next_maintenance_date: values.device_next_maintenance_date,
      device_content_id: values.device_content?.value ?? null,
      galileou_enabled: values.galileou_enabled
    };
    if (!device.is_silo) delete payload.device_content_id;
    dispatch(updateDevice(payload));
  });

  return (
    <>
      <Tooltip title={<Trans i18nKey="edit_device"></Trans>}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog} maxWidth="md">
        <FormContext {...form}>
          <form onSubmit={onSubmit} autoComplete="off">
            <DialogTitle>
              {t('edit_device')} {device.device_name}
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
              <Typography>{t('edit_device_popup_lower_text')}</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="device_name"
                label={t('device_name')}
                fullWidth
                name="device_name"
                disabled={updating}
                inputRef={form.register({
                  required: <Trans i18nKey="required_field">Required Field</Trans>
                })}
                error={form.errors.device_name ? true : false}
                helperText={form.errors.device_name && form.errors.device_name.message}
              />
              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={6}>
                  <NanolikeDatePicker
                    name="device_install_date"
                    value={device.device_install_date as Moment}
                    label={t('device_starting_date')}
                    disabled={true}
                    disableFuture
                  />
                </Grid>
                {!device.is_silo && [
                  <Grid item xs={12} md={4}>
                    <NanolikeDatePicker
                      name="device_fabrication_date"
                      value={device.device_fabrication_date as Moment}
                      label={t('device_fabrication_date')}
                      disabled={updating}
                      disableFuture
                    />
                  </Grid>,
                  <Grid item xs={12} md={4}>
                    <NanolikeDatePicker
                      name="device_next_maintenance_date"
                      value={device.device_next_maintenance_date as Moment}
                      label={t('device_next_maintenance_date')}
                      disabled={updating}
                    />
                  </Grid>
                ]}
              </Grid>
              {/*  DEPRECATED ? */}
              {/* <TextField
                margin="dense"
                id="device_description"
                label={t('device_description')}
                fullWidth
                multiline={true}
                rows={4}
                name="device_description"
                disabled={updating}
                inputRef={form.register}
                error={form.errors.device_description ? true : false}
                helperText={form.errors.device_description && form.errors.device_description.message}
              /> */}
              <MultipleAutoComplete
                label={t('select_group')}
                name="groups"
                defaultValue={defaultValue}
                options={groupOptions}
                validate={(value: any) => {
                  if (!value || value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />
              {device.is_silo ? (
                <SingleAutoComplete
                  label={t('device_content')}
                  name="device_content"
                  defaultValue={defaultDeviceContentOption}
                  options={deviceContentsOptions}
                  disableClearable={false}
                />
              ) : (
                <FormControlLabel
                  control={
                    <Controller
                      control={form.control}
                      name="galileou_enabled"
                      as={props => (
                        <Checkbox
                          onChange={e => props.onChange(e.target.checked)}
                          checked={props.checked}
                        />
                      )}
                    />
                  }
                  label={
                    <ListItemText
                      primary={t('automaticPoiAssignationPrimaryText')}
                      secondary={t('automaticPoiAssignationSecondaryText')}
                    />
                  }
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} startIcon={<CancelIcon />}>
                {t('cancel')}
              </Button>
              <Button type="submit" color="primary" startIcon={<SaveIcon />} disabled={updating}>
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};

export default EditDeviceDialog;
