import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { IGroup } from 'shared/model/group.model';
import RoomIcon from '@material-ui/icons/Room';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

export interface IDeviceGroupProps {
  group: IGroup;
  withCount?: number;
}

const DeviceGroup = (props: IDeviceGroupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { group } = props;

  return (
    <Chip
      label={
        group.group_name +
        (props.withCount ? ` (${t('deviceWithCount', { count: props.withCount })})` : '')
      }
      variant="outlined"
      className={classes.chip}
      size="small"
      icon={group.is_poi ? <RoomIcon /> : <></>}
    />
  );
};

export default DeviceGroup;
