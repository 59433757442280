import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React from 'react';
import { Validate, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGroupsDevices } from 'shared/auth/auth-utils';
import { sortOptionsByLabel } from 'shared/utils/data-utils';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import SingleAutoComplete from './singleAutocomplete';

export interface ISelectDeviceProps {
  name: string;
  label?: string;
  deviceGroupLabel?: string;
  disabled: boolean;
  required?: boolean;
  step?: number;
  activeStep?: number;
  onSelectionChange?: Function;
  useRefDevice?: boolean;
  filterCombineDevice?: boolean;
  validate: Validate;
}

const SelectDevice = (props: ISelectDeviceProps) => {
  const { t } = useTranslation();
  const allGroups = useSelector(({ group }: IRootState) => group.groups);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  const form = useFormContext();
  const {
    name,
    label = t('select_device'),
    deviceGroupLabel = t('device'),
    disabled,
    onSelectionChange,
    validate,
    useRefDevice = false,
    filterCombineDevice = true,
    ...rest
  } = props;

  const devices = getGroupsDevices(allGroups, filterCombineDevice);

  const devicesOptions = devices
    .map(aDevice => ({
      label: getDeviceLabel(aDevice, isSilo),
      value: useRefDevice ? aDevice.device_reference : aDevice.device_id,
      group: deviceGroupLabel
    }))
    .sort(sortOptionsByLabel);

  const defaultOptions = devicesOptions.find(deviceOption => {
    const value = form.getValues(name) as ILabelValueOption;
    return value ? deviceOption.value === value.value : false;
  });
  return (
    <Box>
      <SingleAutoComplete
        name={name}
        label={label}
        defaultValue={defaultOptions}
        options={devicesOptions}
        onSelectionChange={onSelectionChange}
        validate={validate}
        disabled={disabled}
        {...rest}
      />
    </Box>
  );
};

export default SelectDevice;
