import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Storage, { LEFT_MENU_OPEN_KEY } from 'shared/utils/storage-utils';

const savedState = Storage.local.get(LEFT_MENU_OPEN_KEY);

const initialState = {
  headerMenuOpen: null as any,
  leftMenuOpen: typeof savedState === 'boolean' ? savedState : true,
  termsOfUseOpen: false
};

export type LayoutState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openHeaderMenu: (state, action: PayloadAction<HTMLElement>) => {
      state.headerMenuOpen = action.payload;
    },
    closeHeaderMenu: state => {
      state.headerMenuOpen = null;
    },
    openLeftMenu: state => {
      state.leftMenuOpen = true;
      Storage.local.set(LEFT_MENU_OPEN_KEY, true);
    },
    closeLeftMenu: state => {
      state.leftMenuOpen = false;
      Storage.local.set(LEFT_MENU_OPEN_KEY, false);
    },
    toggleLeftMenu: state => {
      state.leftMenuOpen = !state.leftMenuOpen;
      Storage.local.set(LEFT_MENU_OPEN_KEY, state.leftMenuOpen);
    },
    openTermsOfUse: state => {
      state.termsOfUseOpen = true;
    },
    closeTermsOfUse: state => {
      state.termsOfUseOpen = false;
    },
    toggleTermsOfUse: state => {
      state.termsOfUseOpen = !state.termsOfUseOpen;
    }
  }
});

export const {
  openHeaderMenu,
  closeHeaderMenu,
  openLeftMenu,
  closeLeftMenu,
  toggleLeftMenu,
  openTermsOfUse,
  closeTermsOfUse,
  toggleTermsOfUse
} = slice.actions;

export default slice.reducer;
