import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  ICallback,
  nanolikeCallbackNotificationChoice,
  nanolikeCallbackType
} from 'shared/model/callback.model';
import CallbackFormFirstStep from './callbackFormFirstStep';
import CallbackFormSecondStep from './callbackFormSecondStep';
import { ICallbackFormResponse } from './createOrEditCallback';

export const callbackTypes: nanolikeCallbackType[] = ['BOTH'];
export const notificationChoices: nanolikeCallbackNotificationChoice[] = ['NONE', 'EMAIL', 'SLACK'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem',
      width: '50vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    content: {
      width: '100%',
      minHeight: '40vh'
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    }
  })
);

export interface ICallbackFormProps {
  onSubmit: (responses: ICallbackFormResponse) => void;
}

const CallbackForm = (props: ICallbackFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const callback = useSelector(({ callbacks }: IRootState) => callbacks.callback);
  const updating = useSelector(({ callbacks }: IRootState) => callbacks.updating);
  const [activeStep, setActiveStep] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;
  const steps = [t('step1'), t('step2')];

  const initialValues = !isNew && callback ? initDefaultValues(callback, t) : {};

  const form = useForm<ICallbackFormResponse>({
    // mode: 'onChange',
    defaultValues: initialValues
  });

  const onSubmit = form.handleSubmit(props.onSubmit);

  const handleNext = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const title = isNew ? t('creation') : t('edit');

  return (
    <FormContext {...form}>
      <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
        <Box className={classes.title}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.content}>
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box pt={2}>
              <CallbackFormFirstStep step={0} activeStep={activeStep} />
              <CallbackFormSecondStep step={1} activeStep={activeStep} />
            </Box>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button
            component={Link}
            to="/callbacks"
            startIcon={<CancelIcon />}
            variant="contained"
            disabled={updating}
          >
            <Hidden xsDown>{t('cancel')}</Hidden>
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />
          <Button
            color="primary"
            variant="contained"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
            disabled={activeStep === 0 || updating}
          >
            <Hidden xsDown>{t('prev')}</Hidden>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            endIcon={<ArrowForwardIcon />}
            disabled={activeStep === steps.length - 1 || updating}
          >
            <Hidden xsDown>{t('next')}</Hidden>
          </Button>
          <Button
            type="submit"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={activeStep !== steps.length - 1 || updating}
            variant="contained"
          >
            <Hidden xsDown>{t('save')}</Hidden>
          </Button>
        </Box>
      </form>
    </FormContext>
  );
};

export default CallbackForm;

/**
 * Init form with default values if exists
 * @param callback
 * @param devices
 * @param groups
 * @param t
 */
const initDefaultValues = (callback: ICallback, t: TFunction) => {
  const groupGroup = t('group');
  const deviceGroup = t('device', { count: 100 });

  const deviceOptions = callback.devices.map(device => ({
    label: device.device_name,
    value: device.device_id,
    group: deviceGroup
  }));

  const groupWatchOptions = callback.groups.map(group => ({
    label: group.group_name,
    value: group.group_id,
    group: groupGroup
  }));
  const device_group = groupWatchOptions.concat(deviceOptions).filter(option => option !== null);

  const data_types = callback.data_types.map(type => ({ label: t(`${type}`), value: type }));

  return {
    ...callback,
    device_group,
    data_types
  };
};
