import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IMe } from 'shared/model/user.model';
import { changePassword } from 'shared/reducers/authenticationSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import { validPasswordStrength } from 'shared/utils/user-utils';
import PasswordTextField from 'shared/widgets/form/passwordTextField';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      marginRight: theme.spacing(1)
    },
    title: {
      display: 'inline-block',
      verticalAlign: 'text-bottom'
    }
  });
});

interface IFormResponse {
  oldPwd: string;
  newPwd: string;
  confirmPwd: string;
}

const UserPassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const updating = useSelector(({ authentication }: IRootState) => authentication.updating);
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const updateSuccess = useSelector(
    ({ authentication }: IRootState) => authentication.changePwdSuccess
  );
  const previousUpdateSuccess = usePrevious<boolean>(updateSuccess);
  const history = useHistory();

  const dispatch = useDispatch();

  const form = useForm<IFormResponse>({
    mode: 'onChange',
    defaultValues: {}
  });

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess === true) {
      history.push('/');
    }
  }, [history, previousUpdateSuccess, updateSuccess]);

  const onSubmit = (responses: IFormResponse) => {
    dispatch(changePassword(me.idUser as string, responses.newPwd, responses.oldPwd));
  };

  return (
    <Box p={2}>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <Box textAlign="center">
            <Typography variant="h5">
              <VpnKeyIcon className={classes.icon} />
              <Box className={classes.title}>{t('change_password_popup')}</Box>
            </Typography>
          </Box>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={10} sm={6} md={4} lg={3}>
              <PasswordTextField
                autoFocus
                fullWidth
                required
                label={t('oldpassword')}
                name="oldPwd"
                disabled={updating}
                inputRef={form.register({
                  required: <Trans i18nKey="required_field">Required Field</Trans>
                })}
                error={form.errors.oldPwd ? true : false}
                helperText={form.errors.oldPwd && form.errors.oldPwd.message}
              />
              <PasswordTextField
                margin="dense"
                required
                fullWidth
                label={t('newpassword')}
                name="newPwd"
                disabled={updating}
                inputRef={form.register({
                  required: <Trans i18nKey="required_field">Required Field</Trans>
                })}
                error={form.errors.newPwd ? true : false}
                helperText={form.errors.newPwd && form.errors.newPwd.message}
              />
              <PasswordTextField
                margin="dense"
                fullWidth
                required
                label={t('newpassword')}
                name="confirmPwd"
                disabled={updating}
                inputRef={form.register({
                  validate: value => {
                    if (!value) {
                      return <Trans i18nKey="required_field">Required Field</Trans>;
                    }

                    const newPwd = form.getValues().newPwd;
                    if (newPwd !== value) {
                      return <Trans i18nKey="different_passwords">different_passwords</Trans>;
                    }
                    if (!validPasswordStrength(value)) {
                      return <Trans i18nKey="password_check">password_check</Trans>;
                    }
                    return true;
                  }
                })}
                error={form.errors.confirmPwd ? true : false}
                helperText={form.errors.confirmPwd && form.errors.confirmPwd.message}
              />
              <Box mt={4} textAlign="center">
                <Button
                  type="submit"
                  color="primary"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled={updating || !form.formState.dirty}
                >
                  {t('save')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </Box>
  );
};

export default UserPassword;
