import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(0.5)
    },
    label: {
      marginRight: theme.spacing(1)
    },
    value: {}
  })
);

export interface ILabelValueProps {
  label: string | undefined;
  value: string | ReactNode | ReactNode[] | undefined;
}

const LabelValue = (props: ILabelValueProps) => {
  const classes = useStyles();

  const { label, value } = props;

  const finalValue = value ? value : '-';

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} sm={6} md={6}>
        <Box className={classes.label} fontWeight="fontWeightBold">
          {label}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box className={classes.value}>{finalValue}</Box>
      </Grid>
    </Grid>
  );
};

export default LabelValue;
