import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { IGroup } from 'shared/model/group.model';

/**
 * @param authorisations { "Group": ["READ", "UPDATE"], ... }
 * @param resource resource to check (Group, Dashboard, ...)
 * @param operation operation to check (READ, CREATE, ...)
 */
const hasAuthorisation = (
  authorisations: Record<string, string[]>,
  resource: string,
  operation: string[] = []
): boolean => {
  if (!authorisations) return false;
  return (
    authorisations[resource]?.includes('*') ||
    authorisations[resource]?.some(op => operation.includes(op))
  );
};

/**
 * @param rightToCheck right to check
 */
export const useIsAuthorised = (resource?: string, operation?: string[]) => {
  const me = useSelector(({ authentication }: IRootState) => authentication.me);
  if (!resource) return true; // if no rights to check, authorized by default (used to menuItem)
  if (!me) return false; // if no user not authorized by default
  // Check Right on whole workspace
  if (hasAuthorisation(me.workspace_role.authorisation, resource, operation)) return true;
};

export const getGroupsDevices = (groups: IGroup[], filterCombineDevice = false) => {
  return groups.reduce((agg: IDevice[], aGroup) => {
    if (aGroup.devices) {
      const devicesToAdd = aGroup.devices.filter(aDevice => {
        if (filterCombineDevice && aDevice.is_combined) {
          return false;
        }

        return !agg.some(existingDevice => existingDevice.device_id === aDevice.device_id);
      });

      return agg.concat(devicesToAdd);
    }
    return agg;
  }, []);
};
