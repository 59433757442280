import MomentUtils from '@date-io/moment';
import { Button, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IRootState } from 'config/store';
import LoadingSettings from 'modules/home/loading-settings';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IMe } from 'shared/model/user.model';
import { fetchSettings } from 'shared/reducers/settingsSlice';
import Landing from 'shared/routes/landing';
import { changeChatLang, setUserWorkspaceChat } from 'shared/utils/chat.utils';
import { setUserProperties } from './shared/utils/analytics-utils';
import { setupI18nextFormatters } from 'shared/utils/lang-utils';
import { loadMapScript } from 'shared/widgets/map/nanolikeMap';
import Notifier from 'shared/widgets/notifier';
import useConfigTheme from './config/theme';
import i18nConfig, { otherConfig } from 'config/i18n';
import { customNotification } from 'shared/reducers/notifierSlice';
import { useSnackbar } from 'notistack';
import { getBinconnectUrl, getEnvWorkspace } from 'config/env';

const App = () => {
  const dispatch = useDispatch();
  const me = useSelector(({ authentication }: IRootState) => authentication.me) as IMe;
  const fetchSettingsSuccess = useSelector(
    ({ settings }: IRootState) => settings.fetchSettingsSuccess
  );
  const loading = useSelector(({ settings }: IRootState) => settings.loading);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const config = useSelector(({ settings }: IRootState) => settings.settings);
  // const isIbc = workspaceIsIbc(settings);
  const { closeSnackbar } = useSnackbar();
  const configTheme = useConfigTheme();
  const [configTranslation, setConfigTranslation] = useState(i18nConfig);
  const { t } = useTranslation();

  useEffect(() => {
    loadMapScript('en');
    dispatch(fetchSettings());
    setUserWorkspaceChat();
  }, [dispatch]);
  useEffect(() => {
    if (me) {
      if (configTranslation.language !== me.configuration.preferred_language) {
        loadMapScript(me.configuration.preferred_language);
        configTranslation.changeLanguage(me.configuration.preferred_language);
      }
      changeChatLang(me.configuration.preferred_language);
      setUserProperties(me.idUser, getEnvWorkspace(), me.workspace_role?.name);
      moment.locale(me.configuration.preferred_language);
      if (me.role.is_nanolike) moment.tz.setDefault(me.configuration.preferred_timezone);
    }
  }, [me, configTranslation]);
  useEffect(() => {
    const useCase = settings.find(s => s.key === 'useCase')?.value;
    if (useCase)
      setConfigTranslation(
        otherConfig({
          overloadTranslationOptionHandler: () => ({ context: useCase })
        })
      );
    setupI18nextFormatters(settings);
  }, [settings]);
  useEffect(() => {
    // dirty redirection handling
    if (getEnvWorkspace() === 'binconnect')
      window.open(
        `//${window.location.host.replace('binconnect', 'demo')}`,
        '_self',
        'noopener,noreferrer'
      );
  }, []);
  useEffect(() => {
    if (config?.settingV2 === 'partial')
      dispatch(
        customNotification({
          preventDuplicate: true,
          message: 'new_platform_notification',
          type: 'info',
          key: 'v2notif',
          persist: true,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          action: (
            <>
              <Button
                style={{ color: '#FFFFFF' }}
                onClick={() => window.open(getBinconnectUrl(), '_self', 'noopener,noreferrer')}
              >
                {t('see')}
              </Button>
              <Button color="secondary" onClick={() => closeSnackbar('v2notif')}>
                {t('close')}
              </Button>
            </>
          )
        })
      );
    if (config?.settingV2 === 'total')
      window.open(getBinconnectUrl(), '_self', 'noopener,noreferrer');
  }, [config, dispatch, closeSnackbar, t]);

  return (
    <I18nextProvider i18n={configTranslation}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={configTranslation.language}
      >
        <Router>
          {loading ? (
            <LoadingSettings />
          ) : fetchSettingsSuccess ? (
            <>
              <Helmet>
                <title>{configTheme.title}</title>
                <link rel="icon" type="image/png" href={configTheme.logoSmall} />
              </Helmet>
              <MuiThemeProvider theme={configTheme.muiTheme}>
                <CssBaseline />
                <Notifier />
                <Landing />
              </MuiThemeProvider>
            </>
          ) : null}
        </Router>
      </MuiPickersUtilsProvider>
    </I18nextProvider>
  );
};

export default App;
