import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import React from 'react';
import { IUser } from 'shared/model/user.model';
import UserAvatar from 'shared/widgets/userAvatar';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    avatarGroup: {
      '&>.MuiAvatarGroup-avatar': {
        fontSize: '1rem',
        height: '36px',
        width: '36px'
      }
    }
  });
});

export interface IUsersAvatarProps {
  users: IUser[];
}

const UsersAvatar = (props: IUsersAvatarProps) => {
  const classes = useStyles();

  return (
    <AvatarGroup max={4} spacing="small" className={classes.avatarGroup}>
      {props.users.map(user => (
        <UserAvatar key={user.idUser} user={user} />
      ))}
    </AvatarGroup>
  );
};

export default UsersAvatar;
