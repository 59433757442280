import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HistoryIcon from '@material-ui/icons/History';
import RefreshIcon from '@material-ui/icons/Refresh';
import TodayIcon from '@material-ui/icons/Today';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { kpiType } from './kpiForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '100%',
      height: '32px'
    }
  })
);

export interface IKpiTypeProps {
  type: kpiType;
  className?: string;
}

const KpiType: React.FunctionComponent<IKpiTypeProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { type, className } = props;

  let title = type as string;
  let icon = null;
  switch (type) {
    case 'use':
      title = 'ibc_statistics';
      icon = <DateRangeIcon className={classes.icon} />;
      break;
    case 'month':
      title = 'monthly_kpi';
      icon = <TodayIcon className={classes.icon} />;
      break;
    case 'rotation':
      title = 'ibc_rotations';
      icon = <RefreshIcon className={classes.icon} />;
      break;
    case 'history':
      title = 'poi-historic';
      icon = <HistoryIcon className={classes.icon} />;
  }

  return (
    <Box p={1} className={className}>
      <Box>{icon}</Box>
      <Box>{t(title)}</Box>
    </Box>
  );
};

export default KpiType;
